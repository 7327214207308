<template>
    <div class="hello m-5">
        <div class="d-flex justify-content-end">
            <div class="w-50">
                <h4 class="m-1">
                    Tags
                </h4>
            </div>
            <div class="w-50">
                <div class="d-flex justify-content-end">
                    <router-link
                        to="/reviews"
                        class="text-white btn-sm btn btn-primary m-2"
                    >
                    <i class="bi bi-caret-left-fill"></i>
                        Back
                    </router-link>
                    <a
                        href="javascript:;"
                        class="text-white btn-sm btn btn-primary m-2"
                        data-bs-toggle="modal"
                        data-bs-target="#addModal"
                        @click="resetFormData"
                    >
                        Add
                    </a>
                </div>
            </div>
        </div>
        <vue-good-table
            mode="remote"
            @on-search="onSearch"
            @on-sort-change="onSortChange"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            :rows="listing"
            :columns="columns"
            :isLoading="isLoading"
            :totalRows="totalRows"
            :sort-options="{
                enabled: true
            }"
            :search-options="{ 
                enabled: true,
                placeholder: 'Search',
            }"
            :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                perPageDropdown: [
                    10,
                    20,
                    30,
                    40,
                    50
                ],
                perPage: 10,
                mode: 'pages',
            }"
        >
            <template slot="table-row" slot-scope="props">
                <div class="widget-title mb-1">
                {{
                    props.column.field == 'title' ? props.formattedRow[props.column.field] : ''
                }}
                </div>
                <div v-if="props.column.field == 'title' && props.row.user_id == null">
                    <span class='bg-primary badge'>Central Tag</span>
                    <span v-if="props.row.tag_category" class='bg-info badge ms-1'>{{ props.row.tag_category.title }}</span>
                </div>

                <template v-if="props.column.field == 'status'">
                    <div class="form-check form-switch">
                        <input
                            :disabled="props.row.user_id == null"
                            type="checkbox"
                            class="form-check-input"
                            v-model="props.formattedRow.status"
                            @change="(e) => onStatusChange(e, props.formattedRow)"
                        />
                    </div>
                </template>
                <template v-if="props.column.field == 'actions'">
                    <button
                        class="btn btn-success m-1"
                        :data-bs-toggle="props.row.user_id ? 'modal' : 'disabled'"
                        data-bs-target="#updateModal"
                        @click="props.row.user_id ? openUpdateModal(props.formattedRow.id) : () => {}"
                        :disabled="props.row.user_id == null"
                    >
                        <i class="bi bi-pencil"></i>
                    </button>
                    <button
                        class="btn btn-danger open_delete_modal"
                        :data-bs-toggle="props.row.user_id ? 'modal' : 'disabled'"
                        data-bs-target="#deleteModal"
                        :data-id="props.formattedRow.id"
                        @click="props.row.user_id ? onOpenDeleteModal(props.formattedRow.id) : () => {}"
                        :disabled="props.row.user_id == null"
                    >
                        <i class="bi bi-trash-fill"></i>
                    </button>
                </template>
            </template>
        </vue-good-table>
        
		<div class="modal fade" id="deleteModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="deleteModalLabel">
                            <i class="bi bi-person-x m-1"></i> Delete Record
                        </h5>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure, you want to delete?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-danger delete_record_btn" data-id="" @click="deleteRecord" data-bs-dismiss="modal">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

		<div class="modal fade" id="updateModal">
            <div class="modal-dialog">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Update
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
					<form @submit.prevent="update">
                    	<div class="modal-body p-3">
							<div class="row">
								<div class="col-md-12">
									<div class="mb-3">
										<label for="title" class="form-label">Title</label>
										<input
                                            id="title"
										 	type="text"
											class="form-control"
											:class="[$v.formFields.title.$error ? 'is-invalid' : '']"
											v-model="formFields.title"
										>
									</div>
                                    <div class="mb-3">
                                        <label class="form-check-label m-b-10" for="status">
                                            Status
                                        </label>
                                        <div class="form-check form-switch">
                                            <input
                                                id="status"
                                                type="checkbox"
                                                class="form-check-input"
											    v-model="formFields.status"
                                            />
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<button type="submit" class="btn btn-primary" :disabled="formLoading">
                                Update
                                <Loading v-if="formLoading" />
                            </button>
						</div>
					</form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="addModal">
            <div class="modal-dialog">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Add
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
					<form @submit.prevent="add">
                    	<div class="modal-body p-3">
							<div class="row">
								<div class="col-md-12">
                                    <div class="mb-3">
										<label for="title" class="form-label">Title</label>
										<input
                                            id="title"
										 	type="text"
											class="form-control"
											:class="[$v.formFields.title.$error ? 'is-invalid' : '']"
											v-model="formFields.title"
										>
									</div>
                                    <div class="mb-3">
                                        <label class="form-check-label m-b-10" for="status">
                                            Status
                                        </label>
                                        <div class="form-check form-switch">
                                            <input
                                                id="status"
                                                type="checkbox"
                                                class="form-check-input"
											    v-model="formFields.status"
                                            />
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<button type="submit" class="btn btn-primary" :disabled="formLoading">
                                Save
                                <Loading v-if="formLoading" />
                            </button>
						</div>
					</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import Loading from '../reviews/loading.vue';

export default {
    name: 'tags',
    components: {
        vSelect,
        Loading
    },
    data() {
        return {
            formFields: {
                id: null,
                title: '',
                status: 1
            },
            columns: [
                {
                    label: 'id',
                    field: 'id',
                    hidden: true
                },
                {
                    label: 'Tag',
                    field: 'title',
                    sortable: true,
					width: '25%'
                },
                {
                    label: 'Status',
                    field: 'status',
                    sortable: false,
					width: '25%',
                },
                {
                    label: 'Action',
                    field: 'actions',
                    sortable: false,
					width: '25%',
                    globalSearchDisabled: true,
                }
            ],
            listing:[],
            tagsCategories:[],
            totalRows:null,
            isLoading:true,
            formLoading:false,
        }
    },
    validations: {
        formFields: {
            title: { 
                required
            },
            status: { 
                required
            }
        }
    },
    computed: {},
    created: function(){
        this.isLoading = true
        this.fetchListing();
    },
    methods: {
        ...mapActions([
            'addUserTag',
            'getUserTag',
            'deleteUserTag',
            'updateUserTag',
            'getUserTags',
        ]),
        add: function(e) {
            e.preventDefault()
            this.$v.formFields.$touch()
            if (!this.$v.formFields.$invalid)
            {
                this.formLoading = true
                this.addUserTag(this.formFields)
                .then((resp) => {
                    if(resp.status)
                    {
                        this.resetFormData()
                        this.closeModal('#addModal')
                        this.notify(resp.message)
                        this.refresh()
                    }
                    else
                    {
                        this.notify(resp.message,'danger')
                    }
                    this.formLoading = false
                }).catch((resp) => {
                    this.notify("Something went wrong please in some time",'danger')
                    this.formLoading = false
                });
            }
        },
        update: function(e) {
            e.preventDefault()
            this.$v.formFields.$touch()
            if (!this.$v.formFields.$invalid)
            {
                this.formLoading = true
                this.updateUserTag(this.formFields)
                .then((resp) => {
                    if(resp.status)
                    {
                        this.resetFormData()
                        this.closeModal('#updateModal')
                        this.notify(resp.message)
                        this.refresh()
                    }
                    else
                    {
                        this.notify(resp.message,'danger')
                    }
                    this.formLoading = false
                }).catch((resp) => {
                    this.notify("Something went wrong please in some time",'danger')
                    this.formLoading = false
                });
            }
        },
        openUpdateModal:function(id){
            this.getUserTag(id).then((resp) => {
                if(resp.status)
                {
                    let {data} = resp
                    if(data)
                    {
                        this.formFields = {
                            id: data.id,
                            title: data.title,
                            status: data.status,
                        }
                    }
                }
                else
                {
                    this.notify(resp.message,'danger')
                }
            })
        },
        resetFormData:function(){
            this.formFields = {
                id:null,
                title:'',
                status:1,
            }
            this.$v.$reset()
        },
        onPageChange: function (data){
            this.fetchListing(data.currentPage)
        },
        onPerPageChange: function (data){
            this.fetchListing(data.currentPage, data.currentPerPage)
        },
        onSortChange: function (data){
            this.fetchListing(1, '', data[0].field, data[0].type)
        },
        onSearch: function (data){
            this.fetchListing(1,'','','',data.searchTerm)
        },
        fetchListing: function(page = 1, limit = '', field = '', direction = '', search = ''){
            this.isLoading = true
            direction = direction != 'none' ? direction : '';
            
            this.getUserTags({page, limit, field, direction, search}).then((resp) => {
                this.totalRows = resp.total
                this.listing = resp.data
                this.isLoading = false
            }).catch(() => {
                console.log(" Something went wrong please try later ")
            })
        },
        deleteRecord: async function(e){
            let id = e.target.getAttribute('data-id');
            let resp = await this.deleteUserTag(id)
            if(resp && resp.status)
            {
                let deleteBtn = document.querySelector(`button.open_delete_modal[data-id='${id}']`); 
                deleteBtn.parentElement.parentElement.remove()
                this.notify(resp.message)
            }
            else
            {
                this.notify(resp.message,'danger')
            }
        },
        onOpenDeleteModal: function(id){
            let deleteModal = document.querySelector('div#deleteModal')
            if(deleteModal)
            {
                deleteModal.querySelector('button.delete_record_btn').setAttribute('data-id', id)
            }
        },
        closeModal:function(modal){
            let closeBtn = document.querySelector(modal).querySelector('.close-icon')
            if(closeBtn)
            {
                closeBtn.click()	
            }
        },
        refresh:function(){
            this.onSearch('');
        },
        notify:function(msg, type = 'success'){
            if(type)
            {
                this.$notify(msg, type)
            }
            else
            {
                this.$notify(msg)
            }
        },
        onStatusChange:function(e, row){
            let temp = {
                id:row.id,
                title:row.title,
                status:e.target.checked ? 1 : 0
            }
            this.updateUserTag(temp).then((resp) => {
                if(resp.status)
                {
                    this.notify(resp.message)
                }
                else
                {
                    this.notify(resp.message ? resp.message : 'something went wrong please try later' ,'danger')
                }
            })
        }
    }
}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.form-switch .form-check-input {
    width: 50px !important;
    height: 2rem !important;
}
.close-icon {
	position: absolute;
	top: 10px;
	right: 10px;
}

.openModalBusiness { 
	cursor: pointer;
}

.rounded-circle{
    content: "";
    position: absolute;
    top: -1px;
    right: -3px;
    width: 8px;
    height: 8px;
}
</style>