<template>
	<div
		class="row mb-4 rounded shadow-sm"
		v-bind:style="[
		feeds.already_used
			? { 'background-color': '#f7f7e6', border: '1px solid #c6c6a3 ' }
			: { 'background-color': '#FFFFFF', border: '1px solid #cccccc' },
		]"
	>
		<div class="row m-0 d-flex px-0">
		<div class="col-md-9 p-4">
			<h4 class="fw-bold">
				<u v-html="feeds.title"></u>
				<span class="ms-3 fs-5 cursor-pointer" v-tooltip="'Edit Feed'" title="Edit Feed">
					<a
						href="javascript:;" 
						v-if="feeds.is_scheduled"
						@click="editBtnWarningMsg"
					>
						<i class="bi bi-pencil-square"></i>
					</a>
					<router-link 
						v-else
						class=""
						aria-current="page"
						style="text-decoration: none"
						:to=" `/edit/post/${feeds.id}?IC=${isClonned}&&ITBC=${isToBeClonned}`"
					>
						<i class="bi bi-pencil-square"></i>
					</router-link>
				</span>
			</h4>
			<span class="mb-3 d-block">
				<span class="fw-bold">Type:&nbsp;</span>
				<span v-if="feeds.type == 'events'">observance&nbsp;&nbsp;&nbsp;</span>
				<span v-if="feeds.type != 'events'">{{ feeds.type }}&nbsp;&nbsp;&nbsp;</span>
				<span v-if="feeds.type == 'events' && feeds.obs_day > 1">
					<span class="fw-bold">Date:</span>
					{{ monthOptions && monthOptions[feeds.obs_month - 1].name }}
					{{ dayOptions && dayOptions[feeds.obs_day - 1].name }}
				</span>
				<span v-if="feeds.type == 'events' && feeds.obs_day < 2">
					<span class="fw-bold">Date:</span>
					{{ monthOptions && monthOptions[feeds.obs_month - 1].name }}
					{{ dayOptions && dayOptions[0].name }}
				</span>
			</span>
			<div
				class="p-3 d-block mb-4 unselectable"
				v-bind:style="[
					feeds.already_used
					? {
						'background-color': '#f7f7e6',
						border: '2px dotted #c6c6a3 '
					}
					: {
						'background-color': '#f7f7f7',
						border: '2px dotted #cccccc'
					}
				]"
				unselectable="on"
				>
				<div class="noselect feed-body" style="white-space: pre-line" v-html="feeds.body"></div>
			</div>

			<div class="">
				<div class="d-flex">
					<div class="me-2">
						<button
							type="button"
							v-if="!feeds.is_scheduled"
							@click="openSchedule(feeds)"
							class="btn btn-info text-white"
						>
						Schedule
						<span v-if="isSpin" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					</button>
					<button 
						type="button"
						v-if="feeds.is_scheduled" 
						@click="openReSchedule(feeds)"
						class="btn btn-secondary bg-light text-dark">
						Re-Schedule
					</button>
					<button
						type="button"
						v-if="feeds.is_scheduled"
						@click="cancelScheduleFeed(feeds)"
						:class="isCancel == feeds.id ? 'disabled' : ''"
						class="ms-2 btn btn-secondary text-light"
					>
						Cancel Feed
						<span v-if="isCancel == feeds.id" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
					</button>
				</div>
			</div>
			<div class="d-flex justify-content-between" v-if="feeds.last_used || (parseInt(feeds.client_usage_count) + parseInt(feeds.used)) >= 10 ">
				<div class="wbox-stats">
					<span class="m-1" v-if="feeds.scheduled_date">
						<b>{{ feeds.is_scheduled == 1 ? 'Scheduled' : 'Last Scheduled' }} Date time: </b>
						<span class="lastused" style="font-size: 11px">
							{{ formatDateTime(feeds.scheduled_date) }}
						</span>
					</span>
					<span class="m-1" v-if="feeds.last_used">
						<b>Last Used: </b>
						<span class="lastused" style="font-size: 11px">{{feeds.last_used | moment("YYYY-MM-DD")}}</span>
					</span>
					<span v-if="(parseInt(feeds.client_usage_count) + parseInt(feeds.used)) >= 10">
						<b>Total Usages: </b>
						<span class="usedcount" style="font-size: 11px">
							{{ parseInt(feeds.client_usage_count) + parseInt(feeds.used)}}
						</span>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-3 p-4 my-auto" v-if="feeds.media.type == 'image'">
		<div class="card-images m-0">
			<div class="text-center position-relative h-100" :class="!isImage ? 'p-2' : ''">
				<img
					v-if="feeds.media && !isImage && isImageLoad != feeds.id"
					:id="`feed-img-${feeds.id}`"
					:src="feeds.media.url"
					class="content-img1"
					alt=""
					@click="showImgInPopup($event, feeds.id)"
				/>
				<div v-if="isImage || isImageLoad == feeds.id" class="position-absolute d-flex h-100 w-100 justify-content-center align-items-center" >
					<div class="spinner-border" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>
		</div>
		<div v-if="feeds.client_logo_support && feeds.media != null" class="pt-3 gap-2 d-flex align-items-center justify-content-center mb-1">
			<div class="form-check form-switch position-relative">
				<span
					v-if="uploadLogoShow == feeds.id"
					:id="'logo-spinner'"
					class="spinner-border spinner-border-sm text-success custom-spinner position-absolute"
					role="status"
					aria-hidden="true">
				</span>
				<input
					v-if="uploadLogoShow != feeds.id && isImageLoad != feeds.id"
					v-bind:style="{ cursor: 'pointer' }"
					:disabled="typeof isImageLoad == 'number'"
					class="form-check-input custom-checkbox border-success mt-0 position-absolute"
					type="checkbox"
					:id="'fd-logo'"
					@change="updateLogoStatus($event, feeds)"
					v-bind:checked="feeds.logo_status == 1 ? true : false"
					/>
			</div>
			<span>
				{{feeds.logo_status == 1 ? "Remove your logo" : "Display your logo"}}
			</span>
		</div>
	</div>

	<div class="col-md-3 py-2 my-auto" v-else-if="feeds.media.type == 'url'">
		<div class="card-images m-0" :class="[!isImage ? 'h-auto' : '']">
			<div class="text-center position-relative h-100" :class="[!isImage ? 'p-2' : '']">
				<template v-if="['gif','jpg','jpeg','png'].includes(checkUrlExtension(feeds.media.url)) || feeds.type == 'review'">
					<img 
						v-if="feeds.media && !isImage && isImageLoad != feeds.id"
						:id="`feed-img`"
						:src="feeds.media.url"
						class="content-img1"
						alt=" "
						@click="showImgInPopup($event, feeds.id)"
					/>
					<div v-if="isImage || isImageLoad == feeds.id" class="position-absolute d-flex h-100 w-100 justify-content-center align-items-center">
						<div class="spinner-border" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</template>
				<div class="h-auto m-0" v-else-if="checkUrlExtension(feeds.media.url) == 'youtube'">
					<videoCard :feed="feeds"></videoCard>
				</div>
				<div class="h-auto m-0" v-else>
					<video :class="`video_player_${feeds.id}`" controls width="100%">
						<source :src="feeds.media.url ? feeds.media.url : getVideoUrl()" >
						Your browser does not support HTML5 video.
					</video>
				</div>
			</div>
		</div>
	</div>

	<div class="col-md-3 px-4 py-2 my-auto" v-else-if="feeds.media_type && feeds.media_type == 'video'">
		<div class="h-auto m-0" >
			<video :class="`video_player_${feeds.id}`" controls width="100%">
				<source :src="feeds.media.url ? feeds.media.url : getVideoUrl()" >
				Your browser does not support HTML5 video.
			</video>
		</div>
	</div>

	</div>
		<div v-show="sampleBox[ind] && indexSamp" style="width: 95%; margin: 2%">
			<textarea style="width: 100%; height: 200px" v-model="feeds.body"></textarea>
			<button
				class="btn btn-secondary bg-white text-dark"
				@click="hideSampleBox(ind)"
				title="Click to hide sample box this as Used"
				id="undo_93859"
				>
				<i class="fas fa-times"></i>
				HIDE SAMPLE BOX
			</button>
		</div>

		<!-- <schedule-modal :data="user" :load="load" :feed="feeds" ></schedule-modal> -->
		<div class="modal fade" :id="'scheduledModal' + feeds.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title align-items-center" id="exampleModalLabel">
							Schedule Feed
						</h5>
						<button 
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
							@click="cancel"
						>
						</button>
					</div>
					<div v-if="!load && ayr_connected_accounts" class="modal-body">
						<p v-if="today > dateCheck" class="mb-1 text-danger">
							The date has been past so it will get scheduled instantly
						</p>
						<div class="mb-3 d-flex align-items-center">
							<label class="me-2">
								Date<span class="text-danger">*</span>:
							</label>
							<p class="m-0">{{ this.feeds.obs_created_date | moment("YYYY-MM-DD") }}</p>
							<time-picker v-model="ob_time_picker" class="mx-2" type="time" placeholder="Select time" format="HH:mm" lang="jp"></time-picker>
							<i class="bi bi-calendar-check ms-2"></i>
						</div>
						<div class="row align-items-center">
							<div class="col-lg-2">
								<label>Platforms<span class="text-danger">*</span>:</label>
							</div>
							<div class="col-lg-10">
								<div class="d-flex align-items-center flex-wrap platforms">
									<div 
										class="form-check"
										v-for="(item, index) of JSON.parse(ayr_connected_accounts)" 
										:key="index"
									>
										<div v-if="item == 'facebook'" class="selection facebook" :style="disabledPlatforms.includes(item) ? 'background-color:#dbd8d8' : ''" v-tooltip="disabledPlatforms.includes(item) ? 'This platform is unavailable due to media incompatibility.' :'Facebook Page'" >
											<label :style="disabledPlatforms.includes(item) ? 'cursor: not-allowed' :  'cursor: pointer' ">
												<input type="checkbox" :disabled="disabledPlatforms.includes(item)" v-model="forms.Platforms" :value="item"/>
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-facebook"
														viewBox="0 0 16 16"
													>
														<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'instagram'" class="selection instagram" :style="(!isPIntInstaEnabled || disabledPlatforms.includes(item)) ? 'background-color:#dbd8d8' : ''" v-tooltip="isPIntInstaEnabled ? ( disabledPlatforms.includes(item) ? 'This platform is unavailable due to media incompatibility.' : 'Instagram') : 'Instagram sharing is currently disabled for this feed due to the absence of media content.'">
											<label :style="(!isPIntInstaEnabled || disabledPlatforms.includes(item)) ? 'cursor: not-allowed' :  'cursor: pointer' ">
												<input type="checkbox" :disabled="(!isPIntInstaEnabled || disabledPlatforms.includes(item))" v-model="forms.Platforms" :value="item"/>
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-instagram"
														viewBox="0 0 16 16"
													>
														<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'twitter'" class="selection twitter" :style="disabledPlatforms.includes(item) ? 'background-color:#dbd8d8' : ''" v-tooltip="disabledPlatforms.includes(item) ? 'This platform is unavailable due to media incompatibility.' :'Twitter Page'">
											<label :style="disabledPlatforms.includes(item) ? 'cursor: not-allowed' :  'cursor: pointer' ">
												<input type="checkbox" :disabled="disabledPlatforms.includes(item)" v-model="forms.Platforms" :value="item"/>
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-twitter"
														viewBox="0 0 16 16"
													>
														<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
													</svg>
												</span>
											</label>
										</div>
										
										<div v-if="item == 'pinterest'" v-tooltip="isPIntInstaEnabled ? ( disabledPlatforms.includes(item) ? 'This platform is unavailable due to media incompatibility.' : 'Pinterest') : 'Pinterest sharing is currently disabled for this feed due to the absence of media content.' " class="selection pinterest" :style="(!isPIntInstaEnabled || disabledPlatforms.includes(item)) ? 'background-color:#dbd8d8' : ''">
											<label :style="(!isPIntInstaEnabled || disabledPlatforms.includes(item)) ? 'cursor: not-allowed' :  'cursor: pointer' ">
												<input type="checkbox" :disabled="(!isPIntInstaEnabled || disabledPlatforms.includes(item))" v-model="forms.Platforms" :value="item"/>
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-pinterest"
														viewBox="0 0 16 16"
													>
														<path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"/>
													</svg>
												</span>
											</label>
											</div>

											<div v-if="item == 'linkedin'" class="selection linkedin" :style="disabledPlatforms.includes(item) ? 'background-color:#dbd8d8' : ''" v-tooltip="disabledPlatforms.includes(item) ? 'This platform is unavailable due to media incompatibility.' :'Linkedin Page'">
												<label :style="disabledPlatforms.includes(item) ? 'cursor: not-allowed' :  'cursor: pointer' ">
													<input type="checkbox" :disabled="disabledPlatforms.includes(item)" :value="item"/>
													<span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															fill="currentColor"
															class="bi bi-linkedin"
															viewBox="0 0 16 16"
														>
															<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
														</svg>
													</span>
												</label>
											</div>
											
											<div v-if="item == 'gmb'" class="selection google" :style="disabledPlatforms.includes(item) ? 'background-color:#dbd8d8' : ''" v-tooltip="disabledPlatforms.includes(item) ? 'This platform is unavailable due to media incompatibility.' :'Google My Bussiness'">
												<label :style="disabledPlatforms.includes(item) ? 'cursor: not-allowed' :  'cursor: pointer' ">
													<input type="checkbox" :disabled="disabledPlatforms.includes(item)" v-model="forms.Platforms" :value="item"/>
													<span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															fill="currentColor"
															class="bi bi-google"
															viewBox="0 0 16 16"
														>
															<path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
														</svg>
													</span>
												</label>
											</div>

											<div v-if="item == 'telegram'" class="selection telegram" :style="disabledPlatforms.includes(item) ? 'background-color:#dbd8d8' : ''" v-tooltip="disabledPlatforms.includes(item) ? 'This platform is unavailable due to media incompatibility.' :'Telegram'">
												<label :style="disabledPlatforms.includes(item) ? 'cursor: not-allowed' :  'cursor: pointer' ">
													<input type="checkbox" :disabled="disabledPlatforms.includes(item)" v-model="forms.Platforms" :value="item"/>
													<span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="16"
															height="16"
															fill="currentColor"
															class="bi bi-telegram"
															viewBox="0 0 16 16"
														>
															<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
														</svg>
													</span>
												</label>
											</div>
											
											<div v-if="item == 'fbg'" class="selection fb-group" :style="disabledPlatforms.includes(item) ? 'background-color:#dbd8d8' : ''" v-tooltip="disabledPlatforms.includes(item) ? 'This platform is unavailable due to media incompatibility.' :'Facebook Group'" >
												<label :style="disabledPlatforms.includes(item) ? 'cursor: not-allowed' :  'cursor: pointer' ">
													<input type="checkbox" :disabled="disabledPlatforms.includes(item)" v-model="forms.Platforms" :value="item"/>
													<span>
														<img src="../../assets/fb-group.png" class="fb-img" alt=""/>
													</span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<span class="text-danger" style="font-size: 11px" v-if="$v.forms.Platforms.$error">
								field is required
							</span>
						</div>
						<div v-if="!load && !ayr_connected_accounts" class="modal-body">
							<span class="text-danger">
								<button class="btn btn-cus-primary" @click="connectToSocial">
									Connect Social Media Account
									<span v-if="isLoading1" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</button>
							</span>
						</div>
						<loading :active.sync="load" color="#157DEC" :is-full-page="false"></loading>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancel"> 
								{{ !ayr_connected_accounts ? 'Close' : 'Close Pop up' }} 
							</button>
							<button type="submit" v-if="!ayr_connected_accounts" data-bs-dismiss="modal" class="btn btn-primary d-none">
								Ok
							</button>
							<button type="submit" @click="submitForm" v-if="ayr_connected_accounts" :class="isSched ? 'disabled' : ''" class="btn btn-primary">
								Schedule Feed
								<span
									v-if="isSched"
									class="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
									>
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>   

		<div class="modal fade" :id="'reScheduledModal' + feeds.id" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title align-items-center title" id="exampleModalLabel">
							Re-Schedule Feed
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancel"></button>
					</div>
					<div v-if="!load && ayr_connected_accounts" class="modal-body">
						<div class="mb-3" v-if="!feeds.obs_date">
							<label class="me-2">Date<span class="text-danger">*</span>: </label>
							<date-picker
								:show-second="false"
								:default-value="
								new Date(
									new Date().setHours(
										new Date().getHours(),
										new Date().getMinutes() + 5,
										20,
										0
									)
								)"
								:format="'YYYY-MM-DD HH:mm'"
								:disabled-date="notBeforeToday"
								:disabled-time="notBeforeTodayTwelveOClock"
								type="datetime"
								v-model="forms.scheduleDate"
								:class="{'is-invalid': $v.forms.scheduleDate.$error}"
								placeholder="select date"
								valueType="format"
								lang="jp">
							</date-picker>
						</div>
						<div class="mb-3 d-flex align-items-center" v-if="feeds.obs_date">
							<label class="me-2">Date<span class="text-danger">*</span>: </label>
							<p class="m-0">{{ this.feeds.obs_created_date | moment("YYYY-MM-DD") }}</p>
							<time-picker v-model="ob_time_picker" class="mx-2" type="time" placeholder="Select time" format="HH:mm" lang="jp"></time-picker>
							<i class="bi bi-calendar-check ms-2"></i>
						</div>
						<div class="row align-items-center">
							<div class="col-lg-2">
								<label>Platforms<span class="text-danger">*</span>:</label>
							</div>
							<div class="col-lg-10">
								<div class="d-flex align-items-center flex-wrap platforms">
									<div class="form-check"
										v-for="(item, index) of JSON.parse(ayr_connected_accounts)"
										:key="index">
										<div v-if="item == 'facebook'" v-tooltip="'Facebook Page'" class="selection facebook">
											<label v-bind:style="{ cursor: 'not-allowed' }">
												<input type="checkbox" disabled v-model="forms.Platforms" :value="item"/>
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
														<path  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'instagram'" v-tooltip="isPIntInstaEnabled ? 'Instagram' : 'Instagram sharing is currently disabled for this feed due to the absence of media content.'" class="selection instagram" :style="!isPIntInstaEnabled ? 'background-color:#dbd8d8' : ''">
											<label :style="'cursor: not-allowed'">
												<input type="checkbox" disabled v-model="forms.Platforms" :value="item"/>
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
														<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'pinterest'" v-tooltip=" isPIntInstaEnabled ? 'Pinterest' : 'Pinterest sharing is currently disabled for this feed due to the absence of media content.'" class="selection pinterest" :style="!isPIntInstaEnabled ? 'background-color:#dbd8d8' : ''" >
											<label v-bind:style="{ cursor: 'not-allowed' }">
												<input type="checkbox" disabled v-model="forms.Platforms" :value="item"/>
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pinterest" viewBox="0 0 16 16" >
														<path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"/>
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'twitter'" v-tooltip="'Twitter'" class="selection twitter" >
											<label v-bind:style="{ cursor: 'not-allowed' }">
												<input type="checkbox" disabled v-model="forms.Platforms" :value="item"/>
												<span >
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16" >
														<path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'linkedin'" v-tooltip="'Linkedin'" class="selection linkedin" >
											<label v-bind:style="{ cursor: 'not-allowed' }"> 
												<input type="checkbox" disabled v-model="forms.Platforms" :value="item"/>
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
														<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'gmb'" v-tooltip="'Google My Bussiness'" class="selection google">
											<label v-bind:style="{ cursor: 'not-allowed' }">
												<input type="checkbox" disabled v-model="forms.Platforms" :value="item"/>
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
														<path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'telegram'" class="selection telegram" v-tooltip="'Telegram'">
											<label v-bind:style="{ cursor: 'not-allowed' }">
												<input type="checkbox" disabled v-model="forms.Platforms" :value="item"/>
												<span>
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
														<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
													</svg>
												</span>
											</label>
										</div>
										<div v-if="item == 'fbg'" v-tooltip="'Facebook Group'" class="selection fb-group" >
											<label v-bind:style="{ cursor: 'not-allowed' }">
												<input type="checkbox" disabled v-model="forms.Platforms" :value="item"/>
												<span>
													<img src="../../assets/fb-group.png" class="fb-img" alt=""/>
												</span>
											</label>
										</div>
									</div>
									<small class="text-danger">Selected social accounts cannot be modified.</small>
								</div>
							</div>
						</div>
						<span class="text-danger" style="font-size: 11px" v-if="$v.forms.Platforms.$error" >field is required</span>
					</div>
				
					<div v-if="!load && !ayr_connected_accounts" class="modal-body">
						<span class="text-danger">
							<button class="btn btn-cus-primary" @click="connectToSocial">
								Connect Social Media Account
								<span v-if="isLoading1" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							</button>
						</span>
					</div>
					<loading
						:active.sync="load"
						color="#157DEC"
						:is-full-page="false">
					</loading>

					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancel">
							{{ !ayr_connected_accounts ? 'Close' : 'Close Pop up' }}
						</button>
						<button type="submit" v-if="!ayr_connected_accounts" data-bs-dismiss="modal" class="btn btn-primary d-none">
							Ok
						</button>
						<button type="submit" @click="updateForm" v-if="ayr_connected_accounts" :class="isSched ? 'disabled' : ''" class="btn btn-primary">
							Re-Schedule Feed
							<span v-if="isSched" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import moment from "moment-timezone";
	import { mapGetters, mapActions } from "vuex";
	import { required } from "vuelidate/lib/validators";
	import Loading from "vue-loading-overlay";
	import TimePicker from "vue2-datepicker";
	import videoCard from "./video.vue";
	
	export default {
	name: "observanceCard",
	components: {
		moment,
		Loading,
		TimePicker,
		videoCard
	},
	props: {
		feeds: {
		type: Object,
		},
		upId: {
		type: Object,
		},
		ind: Number,
		total_copy_rem_tmp: Number,
		cancelFeed: [Boolean, Number],
		isImageToggle: [Boolean, Number],
	},
	watch: {
		feeds: function (val, oldVal) {
		this.isImage = true;
		setTimeout(() => {
			this.isImage = false;
		}, 1000);
		},
		total_copy_rem_tmp: function (val, oldVal) {
		this.total_copy_rem = parseInt(val);
		},
		cancelFeed: function (val, oldVal) {
		this.isCancel = val;
		},    
		uploadLogoShowToggle: function (val, oldVal) {
		this.uploadLogoShow = val;
		},
		isImageToggle: function (val, oldVal) {
		this.isImageLoad = val;
		},
	},
	data() {
		return {
		isImage: false,
		isImageLoad: false,
		length: "",
		colour: "#ffffff",
		userDataChange: false,
		today: "",
		dateCheck: "",
		url: "",
		forms: {
			scheduleDate: "",
			Platforms: [],
		},
		imgSrc: null,
		content: "",
		load: false,
		isSched: false,
		isCancel: false,
		isSpin: false,
		id: "",
		isLoad: false,
		selectedFile: [],
		user: {},
		ayr_connected_accounts: "",
		refresh: 0,
		schDate: "",
		isLoading1: false,
		expand: false,
		feed_data: {},
		bgColor: false,
		isSample: false,
		sampleBox: [false],
		indexSamp: false,
		isSample1: false,
		showReset: false,
		popupImgSrc: null,
		rediitField: false,
		isImage: false,
		// uploadStatus:0,
		uploadLogoShow: false,
		total_copy_rem: "",
		ob_time_picker:"",
		randNo: Math.floor(Math.random() * 10000000),
		monthOptions: [
			{
			name: "January",
			value: 1,
			},
			{
			name: "February",
			value: 2,
			},
			{
			name: "March",
			value: 3,
			},
			{
			name: "April",
			value: 4,
			},
			{
			name: "May",
			value: 5,
			},
			{
			name: "June",
			value: 6,
			},
			{
			name: "July",
			value: 7,
			},
			{
			name: "August",
			value: 8,
			},
			{
			name: "Septempber",
			value: 9,
			},
			{ name: "October", value: 10 },
			{
			name: "November",
			value: 11,
			},
			{
			name: "December",
			value: 12,
			},
		],
		dayOptions: [
			{
			name: "1st",
			value: 1,
			},
			{
			name: "2nd",
			value: 2,
			},
			{
			name: "3rd",
			value: 3,
			},
			{ name: "4th", value: 4 },
			{
			name: "5th",
			value: 5,
			},
			{ name: "6th", value: 6 },
			{ name: "7th", value: 7 },
			{ name: "8th", value: 8 },
			{ name: "9th", value: 9 },
			{ name: "10th", value: 10 },
			{ name: "11th", value: 11 },
			{ name: "12th", value: 12 },
			{ name: "13th", value: 13 },
			{ name: "14th", value: 14 },
			{ name: "15th", value: 15 },
			{ name: "16th", value: 16 },
			{ name: "17th", value: 17 },
			{ name: "18th", value: 18 },
			{ name: "19th", value: 19 },
			{ name: "20th", value: 20 },
			{ name: "21st", value: 21 },
			{ name: "22nd", value: 22 },
			{ name: "23rd", value: 23 },
			{ name: "24th", value: 24 },
			{ name: "25th", value: 25 },
			{ name: "26th", value: 26 },
			{ name: "27th", value: 27 },
			{ name: "28th", value: 28 },
			{ name: "29th", value: 29 },
			{ name: "30th", value: 30 },
			{ name: "31st", value: 31 },
		],
		isPIntInstaEnabled:true,
		schId:'',
		disabledPlatforms:[]
		};
	},
	validations: {
		forms: {
		scheduleDate: { required },
		Platforms: { required },
		rediitPostType: {},
		},
	},
	computed: {
		...mapGetters(["userList"]),
		isClonned:function(){
			return this.feeds && this.feeds.parent_id ? true : false;
		},
		isToBeClonned:function(){
			return this.feeds && !this.feeds.parent_id && !this.feeds.review_id && this.feeds.type != 'custom' ? true : false;
		}
	},
	created() {
		this.total_copy_rem = parseInt(this.total_copy_rem_tmp);

		var ob_date = new Date(this.feeds.obs_created_date);
		ob_date.setHours(8,0,0,0);
		this.ob_time_picker = ob_date;
		//  this.uploadStatus = this.upload_status
	},
  	methods: {
		...mapActions(["fetchUser", "updateUser"]),
		userData() {
		let id = localStorage.getItem("id");
		this.fetchUser(id)
			.then((res) => {
			if (this.userList.status) {
				this.user = this.userList?.user_data;
				this.schDate = this.feeds?.obs_created_date;
			} else {
				this.$toasted.show(`Something Went Wrong`, {
				theme: "bubble",
				type: "error",
				position: "top-center",
				duration: 1500,
				singleton: true,
				});

				this.load = false;
			}
			})
			.catch(() => {
			this.load = false;
			});
		},
		connectToSocial() {
		this.isLoading1 = true;
		let obj = "";
		setTimeout(() => {
			$(`#scheduledModal${this.feed_data.id}`).modal("hide");
		}, 300);

		axios
			.post(process.env.VUE_APP_BASEURL + "/connect-social-account", obj, {
			headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
			},
			})
			.then((res) => {
			var w = 900;
			var h = 600;
			let url;
			if (res.data?.status) {
				url = res?.data?.url + "&logout=true";

				if (window.innerWidth > 580) {
				this.edititem(url, "web", w, h);
				this.url = res.data?.url;
				//  $('#videoModal1').modal('show');
				//   this.$router.push(res.data?.url)

				this.isLoading1 = false;
				} else {
				window.open(url);
				this.isLoading1 = false;
				}
			} else {
				this.isLoading1 = false;
				this.$toasted.show(`${res.data.error_message}`, {
				theme: "bubble",
				type: "error",
				position: "top-center",
				duration: 2500,
				singleton: true,
				});
			}
			})
			.catch((err) => {
			//console.log(err)
			this.isLoading1 = false;
			this.$toasted.show(`Inernal Server Error`, {
				theme: "bubble",
				type: "error",
				position: "top-center",
				duration: 2500,
				singleton: true,
			});
			});
		},
		edititem(myURL, title, myWidth, myHeight) {
		var left = (screen.width - myWidth) / 2;
		var top = screen.height - myHeight;
		var myWindow = window.open(
			myURL,
			title,
			"toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
			myWidth +
			", height=" +
			myHeight +
			", top=" +
			top +
			", left=" +
			left
		);
		},
		checkCopyLimit() {
		this.$toasted.show(`Your copy Quota limit reached`, {
			theme: "bubble",
			type: "warning",
			position: "top-center",
			duration: 2000,
			singleton: true,
		});
		},
		updateLogo(feed, status) {
		$(".custom-checkbox").prop("disabled", true);
		let obj = {
			feed_id: feed.id,
			logo_status: status,
		};
		this.isImage = obj.feed_id;
		this.uploadLogoShow = feed.id;
		axios
			.post(process.env.VUE_APP_BASEURL + `/update-custom-media`, obj, {
			headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
			},
			})
			.then((res) => {
			if (res.data.status == true) {
				if (status == 1) {
				var logo_text = "Logo Successfully Added";
				} else {
				var logo_text = "Logo Successfully Removed";
				}
				feed.logo_status = status;
				this.$toasted.show(logo_text, {
				theme: "bubble",
				type: "success",
				position: "top-center",
				duration: 2000,
				singleton: true,
				});
				feed.media.url = res?.data?.new_media_url;
				this.isImage = false;
				this.uploadLogoShow = false;
				//console.log('hello')
				//this.$router.go()
				$(".custom-checkbox").prop("disabled", false);
			} else {
				if (status == 1) {
				feed.logo_status = 0;
				} else {
				feed.logo_status = 1;
				}
				this.isImage = false;
				this.uploadLogoShow = false;
				this.$toasted.show(`${res.data?.error_message}`, {
				theme: "bubble",
				type: "error",
				position: "top-center",
				duration: 2000,
				singleton: true,
				});
				$(".custom-checkbox").prop("disabled", false);
			}
			})
			.catch((err) => {
			if (status == 1) {
				feed.logo_status = 0;
			} else {
				feed.logo_status = 1;
			}
			this.isImage = false;
			this.uploadLogoShow = false;
			if (err?.response?.data?.status == false) {
				if (err?.response?.status == 403) {
				this.$emit('LogoError', true);
				this.openUploadLogo(feed);
				} else {
				this.$toasted.show(`${err?.response?.data?.error_message}`, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 2000,
					singleton: true,
				});
				}
			}
			$(".custom-checkbox").prop("disabled", false);
			});
		},
		formatDate(scheduleDate){             
		const localDate = new Date(scheduleDate);
		const year = localDate.getFullYear();
		const month = String(localDate.getMonth() + 1).padStart(2, '0');
		const day = String(localDate.getDate()).padStart(2, '0');
		const normalDate = `${year}-${month}-${day}` + ' ' + localDate.toLocaleTimeString();  
		const formatDate = moment(normalDate).tz(`${localStorage.getItem("time_zone") != '' ? localStorage.getItem("time_zone") : moment.tz.guess()}`).toISOString();
		
		return formatDate;
		},
		submitForm(e) {
		this.isSched = true;
		e.preventDefault();
		this.$v.$touch();      
		this.feeds.obs_created_date = this.formatDate(this.ob_time_picker);
		let obj = {
			platforms: this.forms.Platforms,
			feed_id: this.feed_data.id,
			scheduleDate: this.feeds.obs_created_date,
			
		};
		// if(this.forms.redditPostType.length > 0){
		//    obj['post_type'] = this.forms.redditPostType
		// }
		if (!this.$v.$invalid) {
			axios
			.post(process.env.VUE_APP_BASEURL + "/schedule-feed", obj, {
				headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
				},
			})
			.then((res) => {
				//console.log(res.data)
				//this.$v.$reset();

				$(`#scheduledModal${this.feed_data.id}`).modal("hide");
				this.isSched = false;
				if (res?.data?.status) {
				this.$toasted.show(`Feed Scheduled Successfully`, {
					theme: "bubble",
					type: "success",
					position: "top-center",
					duration: 1500,
					singleton: true,
				});
				this.feeds.is_scheduled = 1
				this.feeds.last_used = res?.data?.last_used
				this.feeds.used = res?.data?.used_count
				this.feeds.scheduled_date = res?.data?.schedule_date
				this.$emit("onSchedule", 1);
				} else {
				this.$toasted.show(`${res?.data?.error_message}`, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 2500,
					singleton: true,
				});
				}
				this.forms.scheduleDate = "";
				this.forms.Platforms = [];
				this.$v.$reset();
			})
			.catch(() => {
				this.isSched = false;
			});
		} else {
			this.isSched = false;
			//alert("form submission failed")
		}
		},
		cancel() {
		// console.log('canc', this.$v.$reset())
		this.$v.$reset();
		},
		cancelScheduleFeed(feed) {
		this.$emit("cancelFeed", feed);
		},
		openSchedule(feed) {
			this.bindings()
		var ob_date = new Date(this.feeds.obs_created_date);
		ob_date.setHours(8,0,0,0);
		this.ob_time_picker = ob_date;

		this.today = moment().toDate();
		this.dateCheck = moment(this.feeds.obs_created_date).toDate();
		this.load = true;
		let curr;
		let dif;
		let c;
		if (feed?.scheduled_date && feed?.scheduled_status != "Cancel") {
			let sc_date = moment(feed.scheduled_date).toDate();
			curr = moment().toDate();
			let sc = moment(sc_date).add(3, "days");
			c = moment(sc_date).add(3, "days").toDate();
			dif = moment.duration(moment(c).diff(curr)).asDays();
		}

		if (feed?.scheduled_date && curr < c) {
			this.$toasted.show(`You can Schedule this feed after ${parseInt(dif)} days`, {
			theme: "bubble",
			type: "warning",
			position: "top-center",
			duration: 2500,
			singleton: true,
			});
			this.load = false;
		} else {
			//console.log('opened')
			// this.userData();
			$(`#scheduledModal${feed.id}`).modal("show");
			axios
			.get(process.env.VUE_APP_BASEURL + "/get-user-data", {
				headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
				},
			})
			.then((res) => {
				if (res?.data?.status) {
				this.ayr_connected_accounts =
					res?.data?.user_data?.ayr_connected_accounts;
				this.forms.scheduleDate = moment(feed.obs_created_date).toDate();
				this.feed_data = feed;
				this.setPlatforms();
				} else {
				this.$toasted.show(`Something Went Wrong`, {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 1500,
					singleton: true,
				});
				}
				this.load = false;
			})
			.catch(() => {
				this.load = false;
			});
		}
		}, 
		openUploadLogo(feed) {
		this.$emit("changeId", feed);
		this.$emit('openUploadLogoFeed', feed)
		},
		changePlus() {
		// console.log('before',this.expand)
		this.expand = !this.expand;
		},
		doCopy() {
		let obj = {
			feed_id: this.feeds.feed_id,
		};
		// console.log(this.feeds);
		axios
			.post(process.env.VUE_APP_BASEURL + "/copyContent", obj, {
			headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
			},
			})
			.then(() => {
			this.$copyText(this.feeds.body);
			if (this.feeds.already_used) {
				this.$emit("onselect", 0);
			} else {
				this.$emit("onselect", 1);
			}
			this.$emit("updateLimit");
			// this.total_copy_rem -= 1
			this.feeds.already_used = 1;
			this.feeds.used = this.feeds.used + 1;
			this.feeds.last_used = new Date();

			this.onCopy();
			//console.log(e)
			})
			.catch((err) => {
			// console.log(err.message)
			if (err.message == "Request failed with status code 422") {
				this.$toasted.show(`Your copy Quota limit reached `, {
				theme: "bubble",
				type: "warning",
				position: "top-center",
				duration: 1500,
				singleton: true,
				});
			}
			});
		},
		downloadImg(responseUrl) {
		saveAs(responseUrl);
		},
		handleReset() {
		let obj = {
			feed_id: this.feeds.feed_id,
		};
		axios
			.post(process.env.VUE_APP_BASEURL + "/resetCopyContent", obj, {
			headers: {
				"Content-type": "application/json",
				token: `${localStorage.getItem("token")}`,
			},
			})
			.then(() => {
			this.$emit("onselect", -1);
			this.feeds.already_used = 0;
			this.showReset = false;
			this.indexSamp = false;
			this.bgColor = false;
			})
			.catch(() => {
			this.$toasted.show(`Something went wrong`, {
				theme: "bubble",
				type: "error",
				position: "top-center",
				duration: 1500,
				singleton: true,
			});
			});
		},
		hideSampleBox(ind) {
		// console.log( this.sampleBox[ind],ind)
		this.indexSamp = false;
		this.sampleBox[ind] = false;
		},
		addDiv(ind) {
		this.indexSamp = true;
		},
		onCopy: function (e) {
		this.bgColor = true;
		this.isSample = true;
		this.sampleBox[this.ind] = true;
		setTimeout(() => {
			this.isSample = false;
			this.showReset = true;
			this.isSample1 = true;
		}, 2500);
		},
		onError: function (e) {
		alert("Failed to copy the text to the clipboard");
		// console.log(e);
		},
		showImgInPopup: function (event, id) {
		this.$emit("showImgInPopup", event, id);
		// this.popupImgSrc = event.target.getAttribute('src')
		// $('#showImgInPopup'+id).modal('show')
		},
		isReddit(e) {
		if (e.target.checked) {
			this.rediitField = true;
		} else {
			this.rediitField = false;
			this.forms.rediitPostType = {};
		}
		},
		getImgUrl(src) {
		return src + "?" + this.randNo;
		},
		updateLogoStatus(e, feeds) {
		if (e.target.checked) {
			this.updateLogo(feeds, 1);
		} else {
			this.updateLogo(feeds, 0);
		}
		},
		openReSchedule(feed) {
			this.bindings()
			this.load = true;
			$(`#reScheduledModal${feed.id}`).modal("show");
			this.schdate = feed.obs_created_date;
			this.$helper.getRequest(`get-user-data`)
			.then((res) => {
				if (res?.status)
				{
					this.ayr_connected_accounts = res?.user_data?.ayr_connected_accounts;
				}
				else
				{
					this.$toasted.show(`Something Went Wrong`, {
						theme: "bubble",
						type: "error",
						position: "top-center",
						duration: 1500,
						singleton: true,
					});
				}
				this.load = false;
			})
			.catch(() => {
				this.load = false;
			});	
		},
		updateForm:function(e) {
			e.preventDefault();
			this.$v.$touch();
			
			this.isSched = true;		

			if(!this.$v.$invalid)
			{
				this.$helper.postRequest(`update-scheduled-feed/${this.schId}`,{schedule_date: this.formatDate(this.ob_time_picker)})
				.then((res) => {
					$(`#reScheduledModal${this.feeds.id}`).modal("hide");
					this.isSched = false;
					if(res?.status)
					{
						this.$toasted.show(`Feed Re-Scheduled Successfully`, {
							theme: "bubble",
							type: "success",
							position: "top-center",
							duration: 1500,
							singleton: true,
						});
						this.feeds.last_used = res?.feedData?.last_used ? moment(res?.feedData?.last_used).format('YYYY-MM-DD') : ''
						this.feeds.used = res?.feedData?.used
						this.feeds.scheduled_date = res?.resp?.scheduleDate
						// this.$emit('scheduled', res?.feedData?.last_used ? moment(res?.feedData?.last_used).format('YYYY-MM-DD') : '')
						/* this.feeds = {
							...this.feeds,
							last_used:res?.feedData?.last_used
						} */
					}
					else
					{
						this.$toasted.show(`${res?.message}`, {
							theme: "bubble",
							type: "error",
							position: "top-center",
							duration: 5500,
							singleton: true,
						});
					}
					/* this.forms.scheduleDate = "";
					this.forms.Platforms = []; */
					this.$v.$reset();
				})
				.catch((err) => {
					this.isSched = false;
					this.$toasted.show(`Internal Server Error`, {
						theme: "bubble",
						type: "error",
						position: "top-center",
						duration: 1500,
						singleton: true,
					});
				});
			}
			else
			{
				this.isSched = false;
			}
		},
		getScheduledInfo:function(){
			this.$helper.getRequest(`get-scheduled-info/${this.feeds.id}`)
			.then((resp) => {
				if(resp.status)
				{
					this.schId = resp.data.sch_id;
					this.forms = {
						...this.forms,
						Platforms:resp.data.platforms ? JSON.parse(resp.data.platforms) : [],
						scheduleDate:resp.data.scheduled_date ? moment(this.feeds.obs_created_date).format('YYYY-MM-DD HH:mm') : '',
					}
					this.ob_time_picker = resp.data.scheduled_date ? new Date(resp.data.scheduled_date) : ''
				}
				else
				{
					this.forms = {
						...this.forms,
						Platforms:[],
						scheduleDate: '',
					}
				}
			})
		},
		bindings:function(){
			
			if(this.feeds.is_scheduled)
			{
				this.getScheduledInfo()	
			}
			
			if(this.feeds.type == 'external' || !this.feeds.media || this.feeds.media_type == 'url')
			{
				this.isPIntInstaEnabled = false
			}
			else
			{
				this.isPIntInstaEnabled = true
			}
		},
		formatDateTime:function(dateTime){
			return moment.utc(dateTime).tz(`${localStorage.getItem("time_zone") != '' ? localStorage.getItem("time_zone") : moment.tz.guess()}`).format("YYYY-MM-DD hh:mm A");
		},
		editBtnWarningMsg:function(){
			alert('Please cancel the feed first in order to update it.')
		},
		getVideoUrl:function(){
			if(this.feeds.video_data)
			{
				let videoData = JSON.parse(this.feeds.video_data)
				if(videoData)
				{
					let videoPlayer = document.querySelector(`video.video_player_${this.feeds.id}`)
					
					if(videoPlayer)
					{
						setTimeout(function(){
							videoPlayer.load()
						},1000)
					}

					if(videoData.videoValidateMode == 'SIMPLE')
					{
						return videoData.validateVideo.SIMPLE+`?${Math.random()}=${Math.random()}sample`
					}
					else if(videoData.videoValidateMode == 'ADVANCED')
					{
						if(videoData.successPlatforms.ADVANCED && videoData.successPlatforms.ADVANCED.length > 0)
						{
							return videoData.validateVideo.ADVANCED[videoData.successPlatforms.ADVANCED[0]]+`?${Math.random()}=${Math.random()}advanced`
						}
						else if(videoData.errorPlatforms.ADVANCED && videoData.errorPlatforms.ADVANCED.length > 0)
						{
							return videoData.validateVideo.ADVANCED[videoData.errorPlatforms.ADVANCED[0]]+`?${Math.random()}=${Math.random()}advanced`
						}
					}
				}
			}
			return '';
		},
		checkUrlExtension:function(url){
			if(url)
			{
				if(url.includes('youtube'))
				{
					return 'youtube';
				}
				else
				{
					url = url.split('?')[0]
					let urlArray = url.split('.');
					if(urlArray.length > 0)
					{
						return urlArray[urlArray.length-1];
					}
				}
			}
			return '';
		},
		setPlatforms:function(){
			if(this.feeds.video_data && this.feeds.media_type == 'video' && this.ayr_connected_accounts)
			{
				let connectedAccounts = JSON.parse(this.ayr_connected_accounts)
				let videoData = JSON.parse(this.feeds.video_data)
				if(videoData)
				{
					if(videoData.videoValidateMode == 'SIMPLE')
					{
						let successPlatforms = videoData.successPlatforms.SIMPLE
						
						successPlatforms.map((item, index) => {
							let indexOf = connectedAccounts.indexOf(item)
							if(indexOf >= 0)
							{
								connectedAccounts.splice(indexOf, 1);
							}
						})

						this.disabledPlatforms = connectedAccounts
					}
					else if(videoData.videoValidateMode == 'ADVANCED')
					{
						let successPlatforms = videoData.successPlatforms.ADVANCED
						
						successPlatforms.map((item,index) => {
							let indexOf = connectedAccounts.indexOf(item)
							if(indexOf >= 0)
							{
								connectedAccounts.splice(indexOf, 1);
							}
						})

						this.disabledPlatforms = connectedAccounts
					}
				}
			}
		}
  	},
};
</script>