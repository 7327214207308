<template>
    <div class="container">
        <custom-filter 
            :allPlatforms="platformForFilter"
            :onApply="filterReviews"
        >
        </custom-filter>
        <div class="row align-items-center flex-wrap mt-4 py-3 control-div px-2">
            <div class="p-1 w-auto" aria-label="add-review">
                <div class="d-flex">
                    <div class="mx-1">
                        <i 
                            class="bi bi-info-circle ms-0 mt-3 m-2"
                            v-if="!autoReviewStatus.is_user_review_status || !autoReviewStatus.is_user_connected_social_accounts || !autoReviewStatus.is_user_business_added"
                            v-tooltip="{
                                content: `To enable automatic review fetching from your social media accounts, <a href='javascript:;' class='open_learn_more_modal'>click here</a>.`,
                                autoHide: false,
                                html: true,
                              }"
                            ></i>
                        <button class="btn btn-sm btn-cus-primary px-1 shadow-none open_add_review">
                            <i class="bi bi-plus-circle me-1"></i> 
                            Add Review 
                        </button>
                        <router-link class="me-2 ms-2 btn btn-sm btn-cus-primary px-1 shadow-none" to="/my-tags">
                            Manage My Tags
                        </router-link>
                        <button 
                            v-if="reviews.length > 0"
                            :disabled="reviewMoveStatus ? true : false" 
                            @click="moveReviewsStart"
                            class="btn btn-sm btn-cus-primary px-1 shadow-none">
                            Re-convert Reviews To Feeds
                            <SmallLoading v-if="reviewMoveStatus" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="p-1 w-auto" aria-label="sorting">
                <div class="d-flex align-items-center flex-wrap">
                    <div class="">
                        <label class="text-nowrap text-secondary me-1">
                            Order results by
                        </label>
                    </div>
                    <div class="d-flex">
                        <select
                            v-model="sortingField"
                            aria-label="Default select example"
                            class="form-select text-secondary order-by-filter shadow-none"
                        >
                            <option value="id">Any</option>
                            <option value="created_at">Recently added</option>
                        </select>
                        <select
                            v-model="sortingType"
                            aria-label="Default select example"
                            class="form-select text-secondary ms-2 shadow-none"
                        >
                            <option value="ASC">ASC</option>
                            <option value="DESC">DESC</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="p-1 w-auto" aria-label="pagination">
                <div class="d-flex align-items-center flex-wrap">
                    <p class="text-nowrap pages_count text-secondary mb-0" v-if="totalReviews">
                        Reviews
                        <span class="fw-bold">
                            {{ reviewsListFrom }}
                        </span>
                        to
                        <span class="fw-bold">
                            {{ reviewsListTo }}
                        </span>
                        out of
                        <span class="fw-bold">
                            {{ totalReviews }}
                        </span>
                    </p>
                    <paginate
                        :page-range="3"
                        v-model.lazy="reviewPage"
                        :page-count="reviewLastPage"
                        :click-handler="paginationCallback"
                        :disabled-class="'pagination-disabled'"
                        :prev-link-class="'page-link shadow-none'"
                        :next-link-class="'page-link shadow-none'"
                        :page-link-class="'page-link shadow-none fw-bold'"
                        :page-class="'VuePagination__pagination-item page-item'"
                        :prev-text="`<i class='bi bi-chevron-left icon-bold'></i>`"
                        :next-text="`<i class='bi bi-chevron-right icon-bold'></i>`"
                        :class="paginateDisable ? 'custom-pagination-disabled' : ''"
                        :container-class="'VuePagination__pagination pagination VuePagination__pagination m-0 px-2'"
                    >
                    </paginate>
                </div>
            </div>
            <div class="p-1 w-auto" aria-label="per-page">
                <div class="d-flex">
                    <select 
                        v-model="reviewPerPage"
                        class="form-select text-secondary ps-2 pe-0 shadow-none" 
                        style=" width: 60px; border: 1px solid #dee2e6; border-radius: 0px;"
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    <label class="text-nowrap d-flex align-items-center text-secondary ms-1">
                        Per Page
                    </label>
                </div>
            </div>
        </div>

        <div class="loader-container" v-if="reviews.length > 0 || loading">
            <div class="cards-row"
                :key="index"
                v-for="(review, index) of reviews"
                >
                <Card
                    :tags="tags"
                    :index="index"
                    :review="review"
                    :myTags="myTags"    
                    :ratings="ratings"
                    :platforms="platforms"
                    :onChangeTag="onChangeTag"
                    :updateReview="updateReview"
                    :isLoading="reviewFormLoading"
                    :checkedTagsIds="checkedTagsIds"
                    :maxReviewDescription="maxReviewDescription"
                    :maxReviewShortDescription="maxReviewShortDescription"
                    :reviewDescriptionLimit="reviewDescriptionLimit"
                    @reload="fetchReviews"
                    />
            </div>
            <Loading color="#157DEC" :active.sync="loading" :is-full-page="false" />  
        </div>

        <div v-if="reviews.length < 1 && !loading" class="text-align-center">
            <h4 class="no_data">No Data Found</h4>
        </div>

        <div class="d-flex justify-content-end mb-5 flex-wrap">
            <p class="m-2" v-if="totalReviews">
                Reviews
                <span class="fw-bold">{{ reviewsListFrom }}</span> to
                <span class="fw-bold">{{ reviewsListTo }}</span> out
                of
                <span class="fw-bold">{{ totalReviews }} </span>
            </p>
            <paginate
                :page-range="3"
                v-model.lazy="reviewPage"
                :page-count="reviewLastPage"
                :click-handler="paginationCallback"
                :class="paginateDisable ? 'custom-pagination-disabled' : ''"
                :container-class="'VuePagination__pagination pagination VuePagination__pagination m-0 px-2'"
                :page-class="'VuePagination__pagination-item page-item'"
                :page-link-class="'page-link shadow-none fw-bold'"
                :prev-link-class="'page-link shadow-none'"
                :next-link-class="'page-link shadow-none'"
                :disabled-class="'pagination-disabled'"
                :prev-text="`<i class='bi bi-chevron-left icon-bold'></i>`"
                :next-text="`<i class='bi bi-chevron-right icon-bold'></i>`"
            >
            </paginate>
        </div>

        <div class="modal fade" id="addReviewModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Add Review
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
					<form @submit.prevent="addReview">
                    	<div class="modal-body p-3">
							<div class="row">
								<div class="col-md-12">
                                    <div class="mb-1">
                                        <label :for="`author-name`" class="form-label">Author</label>
                                        <input 
                                            id="author-name"
                                            type="text"
                                            class="form-control"
                                            :class="[$v.reviewFormFields.author.$error ? 'is-invalid' : '']"
                                            v-model="reviewFormFields.author"
                                        />
                                    </div>
                                    <div class="mb-1">
                                        <label :for="`platform`" class="form-label">Platform</label>
                                        <v-select 
                                            :id="`platform`"
                                            v-model="reviewFormFields.platform"
                                            placeholder="Select Platform"
                                            :options="platforms"
                                            :class="[$v.reviewFormFields.platform.$error ? 'is-invalid' : '']"
                                        ></v-select>
                                    </div>
                                    <div class="mb-1">
                                        <label :for="`rating`" class="form-label">Rating</label>
                                        <v-select 
                                            id="rating"
                                            v-model="reviewFormFields.rating"
                                            label="title"
                                            placeholder="Select Rating"
                                            :options="ratings"
                                            :reduce="ratings => ratings.id"
                                            :class="[$v.reviewFormFields.rating.$error ? 'is-invalid' : '']"
                                        ></v-select>
                                    </div>
                                    <div class="mb-3">
                                        <label :for="`description`" class="form-label">Description</label>
                                        <textarea 
                                            rows="3"
                                            id="description"
                                            class="form-control"
                                            v-model="reviewFormFields.text"
                                            :class="[$v.reviewFormFields.text.$error ? 'is-invalid' : '']"
                                        ></textarea>
                                    </div>
                                    <div class="mb-3" v-if="reviewFormFields.text && reviewFormFields.text.length > reviewDescriptionLimit">
                                        <label :for="`short-description`" class="form-label">Short Description</label>
                                        <textarea 
                                            rows="3"
                                            id="short-description"
                                            class="form-control"
                                            v-model="reviewFormFields.short_description"
                                            :class="[$v.reviewFormFields.short_description.$error ? 'is-invalid' : '']"
                                        ></textarea>
                                    </div>
                                </div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<button type="submit" class="btn btn-primary" :disabled="reviewFormLoading">
                                Save
                                <SmallLoading v-if="reviewFormLoading" />
                            </button>
						</div>
					</form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="addModal">
            <div class="modal-dialog">
                <div class="modal-content">
					<div class="d-flex justify-content-center mt-2">
						<h5 class="modal-title">
							Add Tag
						</h5>
					</div>
					<i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
					<hr class="mb-0">
					<form @submit.prevent="addTag">
                    	<div class="modal-body p-3">
							<div class="row">
								<div class="col-md-12">
                                    <div class="mb-3">
										<label for="title" class="form-label">Title</label>
										<input
                                            id="title"
										 	type="text"
											class="form-control"
											:class="[$v.formFields.title.$error ? 'is-invalid' : '']"
											v-model="formFields.title"
										>
									</div>
                                    <div class="mb-3">
                                        <label class="form-check-label m-b-10" for="status">
                                            Status
                                        </label>
                                        <div class="form-check form-switch">
                                            <input
                                                id="status"
                                                type="checkbox"
                                                class="form-check-input"
											    v-model="formFields.status"
                                            />
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<button :disabled="tagLoading" type="submit" class="btn btn-primary">
                                Save
                                <SmallLoading v-if="tagLoading" />
                            </button>
						</div>
					</form>
                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="deleteModalLabel">
                            <i class="bi bi-person-x m-1"></i> Delete Review
                        </h5>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure, you want to delete Review?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-danger delete_record_btn" data-id="" @click="deleteReview" data-bs-dismiss="modal">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="learnMoreModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" id="learnMoreModal">
                            To Enable Automatic Review Fetching, Follow These Steps:
                        </h6>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <b>Connect Your Social Media Accounts</b>
                                <br>
                                Link your social media accounts to get started. 
                                <a v-if="!autoReviewStatus.is_user_connected_social_accounts" href="javascript:;" class="ref_connect_social_network_btn_navbar me-1">Click Here</a>
                                <span v-if="autoReviewStatus.is_user_connected_social_accounts" class="badge bg-success">Completed</span>
                                <span v-else class="badge bg-warning">Pending</span>
                            </li>
                            <li>
                                <b>Enable Review Fetching From Your User Profile</b>
                                <br>
                                Allow us to automatically fetch reviews from your profiles. 
                                <a href="javascript:;" v-if="!autoReviewStatus.is_user_review_status" to="/profile" class="me-1 to_profile">
                                    Click Here
                                </a>
                                <span v-if="autoReviewStatus.is_user_review_status" class="badge bg-success">Completed</span>
                                <span v-else class="badge bg-warning">Pending</span>
                            </li>
                            <li>
                                <b>Add Your Business</b>
                                <br>
                                Provide your business details for us to verify. 
                                <a href="javascript:;" v-if="!autoReviewStatus.is_user_business_added" to="/business" class="me-1 add_business">
                                    Click Here
                                </a>
                                <span v-if="autoReviewStatus.is_user_business_added" class="badge bg-success">Completed</span>
                                <span v-else class="badge bg-warning">Pending</span>
                            </li>
                        </ul>
                        <p>We will verify your business shortly. Please allow us some time to complete this process. If your business is verified, we will proceed with scheduling your review fetching. Otherwise, your business will be flagged as unverified on our system.</p>   
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

import Card from './card.vue'
import CustomFilter from './filter.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import SmallLoading from './loading.vue'

export default {
    name: 'Reviews',
    components: {
        Card,
        Loading,
        vSelect,
        CustomFilter,
        SmallLoading
    },
    watch: {
        reviewPerPage: function (val, oldVal) {
            this.paginateDisable = true
            this.reviewPage = 1
            this.reviewPerPage = parseInt(val)
            this.fetchReviews({
                page:this.reviewPage,
                limit:this.reviewPerPage,
            })
            this.paginateDisable = false
        },
        sortingField: function(val, oldVal){
            this.fetchReviews({})
        },
        sortingType: function(val, oldVal){
            this.fetchReviews({})
        }
    },
    data:function() {
        return {
            tags:[],
            myTags:[],
            reviews:[],
            platforms:[],
            reviewPage:1,
            totalReviews:0,
            loading: true,
            reviewsListTo:0,
            sortingField:'id',
            sortingType:'ASC',
            reviewLastPage:0,
            reviewPerPage:10,
            reviewsListFrom:0,
            checkedTagsIds:{},
            paginateDisable: false,
            reviewFormLoading:false,
            maxReviewShortDescription:0,
            maxReviewDescription:0,
            reviewMoveStatus:false,
            formFields:{
                title:'',
                status:1
            },
            ratings:[
                {
                    id:'1',
                    title:'★☆☆☆☆'
                },
                {
                    id:'2',
                    title:'★★☆☆☆'
                },
                {
                    id:'3',
                    title:'★★★☆☆'
                },
                {
                    id:'4',
                    title:'★★★★☆'
                },
                {
                    id:'5',
                    title:'★★★★★'
                }
            ],
            reviewFormFields: {
                author:'',
                rating:'',
                platform: '',
                text:'',
                short_description:''
            },
            tagLoading:false,
            reviewFormLoading:false,
            filterData:{
                platform:'',
                search:'',
            },
            platformForFilter:[],
            autoReviewStatus:{
                'is_user_business_added':1,
                'is_user_connected_social_accounts':1,
                'is_user_review_status':1
            },
            reviewDescriptionLimit:''
        }
    },
    validations: {
        formFields: {
            title: { 
                required
            },
            status: { 
                required
            }
        },
        reviewFormFields: {
            author: { 
                required
            },
            rating: { 
                required
            },
            platform: { 
                required
            },
            text: { 
                required
            },
            short_description: { 
                // required
            }
        }
    },
    created:function() {
        this.getReviewDescriptionLimit().then((limit) => {
            this.reviewDescriptionLimit = limit
        })
        // this.fetchReviews({})

        this.fetchUserTags()

        this.getSetting(['max_review_short_description','max_review_description','social_platforms']).then((resp) => {
            this.maxReviewDescription = resp.max_review_description;
            this.maxReviewShortDescription = resp.max_review_short_description;
            this.platforms = [];
            if(resp.social_platforms)
            {
                resp.social_platforms.map((item) => {
                    this.platforms.push(item.charAt(0).toUpperCase() + item.slice(1))
                })
            }
        })

        /* this.fetchUserBusiness().then((resp) => {
            if(resp && resp.status)
            {
                let {data} = resp
                this.platforms = [];
                if(data?.reviews_from)
                {
                    data.reviews_from.map((item) => {
                        this.platforms.push(item.charAt(0).toUpperCase() + item.slice(1))
                    })
                }
            }
        }) */

        this.getTagsDropDown({}).then((resp) => {
            if(resp.status)
            {
                this.tags = resp.data
            }
            else
            {
                console.error("Data not found")
            }
        })
        this.jQueryBindings()
        this.checkPlatforms()
        this.getAutoReviewStatusCheck('set')
    },
    mounted:function(){
        let removeEventListener = function(){
            document.removeEventListener("contextmenu",window.$disableRightClick, false);
        } 
        window.onload = removeEventListener
        removeEventListener()
    },
    beforeRouteLeave:function(to, from , next) {
        document.addEventListener("contextmenu",window.$disableRightClick, false);
        this.jQueryBindings('off')
        next()
    },
    methods: {
        ...mapActions([
            'getSetting',
            'getUserReviews',
            'userTagMatchAction',
            'getTagsDropDown',
            'deleteUserReview',
            'updateUserReview',
            'getUsersTagsDropDown',
            'addUserTag',
            'addUserReview',
            'fetchUserBusiness',
            'getReviewDescriptionLimit'
        ]),
        fetchReviews:function({ page = 1, limit = this.reviewPerPage, field = this.sortingField, direction = this.sortingType, filterData = {}, enableLoader = true  }){
            if(enableLoader)
            {
                this.loading = true
            }
            this.reviews = [];
            let { search, platform } = filterData
            search = !search ? this.filterData.search : search;
            platform = !platform ? this.filterData.platform : platform;
            this.getUserReviews({page, limit, field, direction, search, platform}).then(({resp, fullResp}) => {
                if(fullResp.status)
                {
                    this.reviews = resp.data
                    this.totalReviews = resp.total
                    this.reviewsListFrom = resp.from
                    this.reviewsListTo = resp.to
                    this.reviewLastPage = resp.last_page
                }
                else
                {
                    console.error("Data not found")
                }
                this.checkedTagsIds = {};
                this.setCheckedTags()
                if(enableLoader)
                {
                    this.loading = false
                }
            })  
        },
        updateReview:function(data){
            this.reviewFormLoading = true
            let temp = {
                id:data.id,
                author:data.author,
                platform:data.platform,
                rating:data.rating,
                text:data.text,
                short_description:data.short_description ? data.short_description : null,
            };
            return this.updateUserReview(temp).then((resp) => {
                if(resp.status)
                {
                    this.notify(resp.message)
                    this.reviews.map((item, index) => {
                        if(item.id == resp.data.id)
                        {
                            this.reviews[index] = resp.data
                        }
                    })
                    this.checkedTagsIds = {};
                    this.setCheckedTags()
                    // this.fetchReviews({enableLoader:false})
                    this.reviewFormLoading = false
                    this.checkPlatforms()
                    return resp.data
                }
                else
                {
                    this.notify(resp.message, 'danger')
                }
                this.reviewFormLoading = false
                return ''
            })
        },
        setCheckedTags:function(){
            for (let key in this.reviews){
                if(!this.checkedTagsIds[this.reviews[key].id])
                {
                    this.checkedTagsIds[this.reviews[key].id] = {
                        tags:[],
                        smartTags:[],
                    };
                }

                if(this.reviews[key].tags)
                {
                    this.reviews[key].tags.map((item,index) => {
                        if(item.tag_id)
                        {
                            if(!this.checkedTagsIds[this.reviews[key].id].tags.includes(item.tag_id))
                            {
                                this.checkedTagsIds[this.reviews[key].id].tags.push(item.tag_id)
                            }
                        }

                        if(item.smart_tag_id)
                        {
                            if(!this.checkedTagsIds[this.reviews[key].id].smartTags.includes(item.smart_tag_id))
                            {
                                this.checkedTagsIds[this.reviews[key].id].smartTags.push(item.smart_tag_id)
                            }
                        }
                    })
                }
            }
        },
        onChangeTag:async function(e) {
            let that = e.target;
            let {value, checked} = that
            let dataId       = that.getAttribute('dataId'); 
            let dataType     = that.getAttribute('dataType'); 
            let dataParentId = that.getAttribute('dataParentId'); 
            let action       = checked ? 'add' : 'remove';
            dataId = parseInt(dataId);
            let body = {
                action:action,
                review_id:dataParentId,
                type:'tag',
                tag_id:dataId
            }

            let resp = await this.userTagMatchAction(body)
            if(resp.status)
            {
                if(checked)
                {
                    this.checkedTagsIds[dataParentId][dataType].push(dataId)
                }
                else
                {
                    let indexOfId = this.checkedTagsIds[dataParentId][dataType].indexOf(dataId)
                    if(indexOfId >= 0)
                    {
                        this.checkedTagsIds[dataParentId][dataType].splice(indexOfId, 1);
                    }
                }
                this.notify(resp.message)
            }
            else
            {
                this.notify(resp.message,'danger')
            }
        },
        paginationCallback:function(page) {
            this.reviewPage = page
            this.fetchReviews({page})
        },
        filterReviews:function(data){
            this.filterData = data
            this.fetchReviews({
                filterData:data
            })
        },
        deleteReview:function(e){
            this.deleteUserReview(e.target.getAttribute('data-id')).then((resp) => {
                if(resp && resp.status)
                {
                    this.notify(resp.message)
                    this.fetchReviews({});
                    this.checkPlatforms()
                }
                else
                {
                    this.notify(resp && resp.message ? resp.message : 'Something went wrong please try again')
                }
            })
        },
        addTag:function(e) {
            if(!this.tagLoading)
            {
                this.$v.formFields.$touch()
                if(!this.$v.formFields.$invalid)
                {
                    this.tagLoading = true
                    this.addUserTag(this.formFields)
                    .then((resp) => {
                        if(resp.status)
                        {
                            this.resetFormData()
                            $('body').find('#addModal').modal('hide')
                            this.notify(resp.message)
                            this.fetchUserTags()
                        }
                        else
                        {
                            this.notify(resp.message,'danger')
                        }
                        this.tagLoading = false
                    });
                }
            }
        },
        addReview:function(e) {
            this.$v.reviewFormFields.$touch()
            if(!this.$v.reviewFormFields.$invalid)
            {
                this.reviewFormLoading = true
                this.addUserReview(this.reviewFormFields)
                .then((resp) => {
                    if(resp.status)
                    {
                        this.resetReviewFormData()
                        $('body').find('#addReviewModal').modal('hide')
                        this.notify(resp.message)
                        this.fetchReviews({})
                        this.checkPlatforms()
                    }
                    else
                    {
                        this.notify(resp.message,'danger')
                    }
                    this.reviewFormLoading = false
                });
            }
        },
        resetFormData:function(){
            this.formFields = {
                title:'',
                status:1
            }
            this.$v.formFields.$reset()
        },
        resetReviewFormData:function(){
            this.reviewFormFields = {
                author:'',
                rating:'',
                platform: '',
                text:'',
                short_description:''
            }
            this.$v.reviewFormFields.$reset()
        },
        fetchUserTags:function(){
            this.getUsersTagsDropDown().then((resp) => {
                if(resp.status)
                {
                    this.myTags = resp.data
                }
                else
                {
                    console.log("Data not found")
                }
            })
        },
        jQueryBindings:function(action = 'on'){
            let temp = this
            let body = $('body')
            
            let onOpenDeleteModal = (e) => {
                let that    = $(e.target)
                let id      = that.attr('dataId')
                let modal   = body.find('#deleteModal') 
                modal.modal('show')
                modal.find('button.delete_record_btn').attr('data-id', id)
            }

            let openAddTag = () => {
                body.find('#addModal').modal('show')
            }

            let openAddReviewModal = () => {
                body.find('#addReviewModal').modal('show')
            }

            let openLearnMoreModal = () => {
                body.find('#learnMoreModal').modal('show')
            }

            let onConnectClick = () => {
                if(temp.$parent)
                {
                    if(temp.$parent.$children)
                    {
                        if(temp.$parent.$children.length > 0)
                        {
                            if(temp.$parent.$children[0])
                            {
                                if(temp.$parent.$children[0].$refs)
                                {
                                    if(temp.$parent.$children[0].$refs.connectSocialNetworkBtnNavbar)
                                    {
                                        let elem = temp.$parent.$children[0].$refs.connectSocialNetworkBtnNavbar
                                        body.find('#learnMoreModal').modal('hide')
                                        elem.click()
                                        return true;
                                    }       
                                }
                            }   
                        }
                    }   
                }

                alert("Please click the 'Connect Social Network' Button.")
            }

            let addBusinessClick = () => {
                if(temp.autoReviewStatus.is_user_review_status)
                {
                    body.find('#learnMoreModal').modal('hide')
                    temp.$router.push('/business')
                }
                else
                {
                    alert('Please Enable Review Fetching From Your User Profile First To Add Business')
                }
            }

            let profileClick = () => {
                body.find('#learnMoreModal').modal('hide')
                temp.$router.push('/profile')
            }

            if(action == 'on')
            {
                body.on('click','button.open_delete_modal', onOpenDeleteModal)
                body.on('click','a.open_add_tag_modal', openAddTag)            
                body.on('click','button.open_add_review', openAddReviewModal)
                body.on('hide.bs.modal','#addModal', this.resetFormData)
                body.on('hide.bs.modal','#addReviewModal', this.resetReviewFormData)
                body.on('click','.open_learn_more_modal',openLearnMoreModal)
                body.on('show.bs.modal','#learnMoreModal', this.openLearnMore)

                body.on('click','.ref_connect_social_network_btn_navbar', onConnectClick)
                body.on('click','.add_business', addBusinessClick)            
                body.on('click','.to_profile', profileClick)
            }
            else
            {
                body.off('click','button.open_delete_modal', onOpenDeleteModal)
                body.off('click','a.open_add_tag_modal', openAddTag)            
                body.off('click','button.open_add_review', openAddReviewModal)
                body.off('hide.bs.modal','#addModal', this.resetFormData)
                body.off('hide.bs.modal','#addReviewModal', this.resetReviewFormData)
                body.off('click','.open_learn_more_modal',openLearnMoreModal)
                body.off('show.bs.modal','#learnMoreModal', this.openLearnMore)                
    
                body.off('click','.ref_connect_social_network_btn_navbar', onConnectClick)
                body.off('click','.add_business', addBusinessClick)            
                body.off('click','.to_profile', profileClick)
            }
        },
        notify:function(msg, type = 'success'){
            if(type)
            {
                this.$notify(msg, type, 5000)
            }
            else
            {
                this.$notify(msg)
            }
        },
        moveReviewsStart:async function(){
            if(confirm("Are You Sure ?"))
            {
                this.reviewMoveStatus = true;
                let resp = await this.$helper.getRequest(`users/reviews/move-review`)

                if(resp && resp.status)
                {
                    this.notify(resp.message)
                }
                else
                {
                    this.notify(resp.message,'danger')
                }
                this.fetchReviews({})
                this.reviewMoveStatus = false;
            }
        },
        checkPlatforms:function(){
            this.platformForFilter = [];
            this.$helper.getRequest('users/reviews/get-platforms').then((resp) => {
                if(resp.status)
                {
                    if(resp.data)
                    {
                        this.platformForFilter = [
                            ...resp.data
                        ]   
                    }
                }
            })
        },
        openLearnMore:async function(){
            let resp = await this.getAutoReviewStatusCheck('get')

            if(resp && resp.status)
            {
                this.autoReviewStatus = {
                    'is_user_business_added':resp.is_user_business_added,
                    'is_user_connected_social_accounts':resp.is_user_connected_social_accounts,
                    'is_user_review_status':resp.is_user_review_status
                }
            }
            else
            {
                body.find('#learnMoreModal').modal('hide')
                this.notify('Something went wrong please try in some time.')
            }
        },
        getAutoReviewStatusCheck:async function(action = 'get'){
            let resp = await this.$helper.getRequest('users/reviews/check-auto-fetch-reviews-status')
            if(action == 'get')
            {
                return resp   
            }
            else if(action == 'set')
            {
                this.autoReviewStatus = {
                    'is_user_business_added':resp.is_user_business_added,
                    'is_user_connected_social_accounts':resp.is_user_connected_social_accounts,
                    'is_user_review_status':resp.is_user_review_status
                }
            }
        }
    }
}
</script>

<style>
html,
body {
    font-size: 14px;
}

.desc_text {
    border: 1px solid black;
    background: lightgray;
}

.content-img {
    height: 170px !important;
    width: 260px !important;
}
.search_box {
    height: 80%;
}
nav {
    padding: 0px !important;
}
.no_data {
    margin-top: 200px;
    text-align: center;
}
.VuePagination__count {
    display: none !important;
}
.how_works {
    border-color: #4076ad !important;
    background-color: #2c6095 !important;
    text-decoration: none !important;
    color: #fff !important;
}

.video-tutorial-header {
    background: #354a5f;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    text-indent: 10px;
}
#videoModal > .modal-dialog {
    margin: -0.25rem auto !important;
}
label {
    font-size: 12px;
}
.pages_count {
    font-size: 12px;
}
.btn-cus-primary {
    background-color: #05cffdf2 !important;
    color: white !important;
    border-color: #05cffdf2 !important;
}

.page-item.active .page-link {
    z-index: 3;
    background-color: #05cffdf2 !important;
    color: white !important;
    border-color: #05cffdf2 !important;
}

.page-link {
    color: grey !important;
    padding: 0.375rem 0.6rem !important;
}
li.pagination-disabled,
.custom-pagination-disabled .page-item {
    cursor: not-allowed;
}
li.pagination-disabled a,
.custom-pagination-disabled .page-item a {
    pointer-events: none;
}
.icon-bold {
    -webkit-text-stroke: 1px;
}
.selected-logo {
    object-fit: contain;
    max-width: 100% !important;
    max-height: 149px;
    min-width: 149px;
    min-height: 149px;
}

.close-icon {
	position: absolute;
	top: 10px;
	right: 10px;
}

.loader-container{
    position: relative;
    min-height: 400px;
}
</style>