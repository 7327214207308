
import uploadLogo from '../../components/user/uploadLogo/index.vue'

let uploadLogoRoutes = [
    {
        path: "/upload-logo",
        name: 'uploadLogo',
        component: uploadLogo,
        meta: {
            auth: true,
            authOnly: true,
            showDashLayout: true,
            roles: [
                'User'
            ]
        }
    },
];

export default uploadLogoRoutes