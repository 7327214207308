import { getRequest, postRequest } from '@/helper'

let userReviewPostSettingModule = {
    state:{
        postSettings: {}
    },
    getters:{
        postSettings: (state) => {
            return state.postSettings
        }
    },
    actions:{
        getUserPostSetting: async ({ commit }) => {
            let resp = await getRequest(`users/user-post-settings/view`)
            if(resp && resp.status)
            {
                return resp.data
            }
            else
            {
                console.log(resp.message)
            }
        },
        updateUserPostSetting: async({commit}, data) => {
            let {id, template_id, backgroundcolor, secondarycolor, schedule_type, schedule_hour, schedule_month_date, schedule_week_day, social_accounts} = data
            id = id ? `/${id}` : ''
            let resp = await postRequest(`users/user-post-settings/action${id}`, {template_id, backgroundcolor, secondarycolor, schedule_type, schedule_hour, schedule_month_date, schedule_week_day, social_accounts})
            return resp;
        },
        getReviewNote: async ({ commit }, data) => {
            let {weekday, time} = data
            let resp = await getRequest(`users/user-post-settings/get-note?weekday=${weekday}&time=${time}`)
            return resp
        },
    },
    mutations:{
        setPostSetting: (state, data) => {
            return state.postSettings = data
        }
    }
}

export default userReviewPostSettingModule