<template>
    <div class="border bg-white rounded filter_container p-3 mt-4">
        <div class="row filters justify-content-between" v-if="platforms && platforms.length > 2">
            <div class="d-flex align-items-center col-lg-2">
                <p class="align-items-center fw-bold mb-0">Filter by:</p>
                <label class="me-3 text-capitalize ms-3">Platform:</label>
            </div>
            <div class="d-flex align-items-start flex-wrap col-lg-10">
                <button
                    type="button"
                    :key="item.name"
                    v-for="item in platforms"
                    @click="() => { applyFilter(item.value) }"
                    :class="selectedPlatform == item.value ? 'gris' : ''"
                    class="btn btn-outline-secondary text-capitalize px-4 me-lg-3 my-2 mob-100 filter-buttons shadow-none"
                >
                    <span>{{ item.name }}</span>
                </button>
            </div>
        </div>
        <div class="row filters justify-content-between">
            <div class="d-flex align-items-center my-3 col-lg-2 mob-100">
                <p class="fw-bold me-4 mb-0">Search for</p>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3 flex-wrap mob-100 col-lg-10">
                <input
                    type="search"
                    v-model="search"
                    placeholder="Search"
                    v-on:keyup.enter="onEnter"
                    class="form-control search-for mb-3 shadow-none"
                />
                <div class="d-flex d-none">
                    <button 
                        type="button"
                        @click="resetFilter"
                        class="btn btn-sm px-4 py-2 mb-3 btn-secondary me-3 filter-buttons shadow-none"
                    >
                        Reset
                    </button>
                    <button 
                        type="button"
                        @click="applyFilter"
                        class="btn btn-sm px-4 mb-3 btn-cus-primary me-3 filter-buttons shadow-none"
                    >
                        Apply
                    </button>
                </div>
                <div class="heading-right">
                    <router-link to="/post-templates?from=review" class="btn btn-primary px-2 py-1 mx-2 ">
                    Review Settings
                    </router-link>
                </div>
            </div>
        </div>
        <div class="third d-flex align-items-center w-100 justify-content-between"></div>
    </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
    name: 'CustomFilter',
    components: {
        vSelect,
    },
    props:{
        allPlatforms:Array,
        onApply:Function
    },
    watch:{
        allPlatforms:function(val, old){
            let platforms = [
                {
                    name: 'any',
                    value: '',
                }
            ];

            this.allPlatforms.map((item, key) => {
                platforms.push({
                    name:item.toLowerCase(),
                    value:item
                })
            })

            this.platforms = platforms
        }
    },
    data:function() {
        return {
            search: '',
            selectedPlatform:'',
            platforms:[
                {
                    name: 'any',
                    value: '',
                }
            ]
        }
    },
    created:function() {        
    },
    beforeMount:function() {
        let savedData = this.getSavedFilterData();
        if(savedData)
        {
            ['selectedPlatform', 'search'].map((item) => {
                if(savedData[item] != undefined)
                {
                    this[item] = savedData[item] 
                }
                else if(item == 'selectedPlatform')
                {
                    this.selectedPlatform = savedData.platform
                }
            })
        }
        this.applyFilter('')
    },
    methods: {
        onEnter:function() {
            this.applyFilter(this.selectedPlatform)
        },
        applyFilter:function(platform) {
            this.selectedPlatform = platform
            let filterData = {
                platform:this.selectedPlatform,
                search:this.search
            } 
            
            this.onApply(filterData)
            this.saveFilter(filterData)            
        },
        resetFilter:function() {
            this.selectedPlatform = ''
            this.search = ''
            this.saveFilter({})
            this.applyFilter()
        },
        saveFilter:function(data){
            localStorage.setItem('reviews_filter',JSON.stringify(data))
        },
        getSavedFilterData:function(key = null){
            let data = localStorage.getItem('reviews_filter')
            
            data = JSON.parse(data)
            return key ? data[key] : data
        }
    }
}
</script>

<style>
.gris {
    border: 2px solid #05cffdf2 !important;
}
.filter_container {
    padding: 10px;
}
.btn.custom-disabled {
    color: #8d8d8d;
    background-color: transparent;
    border-color: #e3e3e3;
    pointer-events: none;
}
.filter-coming-soon {
    font-size: 10px;
    left: 0;
    bottom: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
</style>