<template>
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto py-5 my-5">
          <div class="page-not-found py-4">
            <div class="d-flex align-items-center justify-content-center">
              <div class="text-center">
                <h1 class="fw-bold mb-5">You're Almost There! </h1>
                <p class="fs-3">
                    Check your inbox for a verification email from us. Click the link inside to activate your account and start using Social Arrow.
                </p>
                <p class="lead">If you don’t see it in a few minutes, check your spam folder or get in touch with us at <a href='mailto:support@socialarrow.com'>support@socialarrow.com</a>.
                </p>
                <!-- <router-link
                  v-if="role == 'User'"
                  to="/"
                  class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-5 py-2 mb-5"
                >
                  Go to Home Page
                </router-link>
                <router-link
                  v-if="role == 'Admin'"
                  to="/admin/users"
                  class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-5 py-2 mb-5"
                >
                  Go to Home Page
                </router-link>
                <router-link
                  v-if="role == null"
                  to="/"
                  class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-5 py-2 mb-5"
                >
                  Go to Home Page
                </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RegisterSuccess",
    data() {
      return {
        role: "",
        defaultUrl: process.env.VUE_APP_DEFAULT_ROUTE,
      };
    },
    created() {
      this.role = localStorage.getItem("role");
      if (this.role == "User") {
        this.$router.push(this.defaultUrl);
      } else if (this.role == "Admin") {
        this.$router.push("/admin/users");
      }
    },
  };
  </script>
  
  <style>
  .payment {
    border: 1px solid #f2f2f2;
    height: 280px;
    border-radius: 20px;
    background: #fff;
  }
  .payment_header {
    background: rgba(255, 102, 0, 1);
    padding: 20px;
    border-radius: 20px 20px 0px 0px;
  }
  
  .check {
    margin: 0px auto;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #fff;
    text-align: center;
  }
  
  .check i {
    vertical-align: middle;
    line-height: 50px;
    font-size: 30px;
  }
  
  .content {
    text-align: center;
  }
  
  .content h1 {
    font-size: 25px;
    padding-top: 25px;
  }
  
  .content a {
    width: 200px;
    height: 35px;
    color: #fff;
    border-radius: 30px;
    padding: 5px 10px;
    background: rgba(255, 102, 0, 1);
    transition: all ease-in-out 0.3s;
  }
  
  .content a:hover {
    text-decoration: none;
    background: #000;
  }
  </style>
  