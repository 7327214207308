<template>
  <div class="col-12 py-5">
    <div class="container">
      <div class="row">
        <div class="mx-auto mb-5">
          <div class="py-5 text-center">
            <img src="/images/payment.png" />
            <h1 class="display-2 fw-bold text-center pt-5 mb-2 text-info">
              Your free trial has ended! 
            </h1>
            <h2 class="fw-bold text-center mb-2">
              Please set up your billing details to activate your subscription.
            </h2>
            <div v-if="pay == 0">
              <h3 class="text-secondary fw-400">
                First {{subscriptionTrialDays}} days free trial, Then {{currency}}{{subscriptionPrice}} per month
              </h3>
            </div>
            <div v-if="pay == 1">
              <h3 class="text-secondary fw-400">{{currency}}{{subscriptionPrice}} per month</h3>
            </div>
            <div class="pt-5">
              <a
                @click="goToPayment"
                class="btn btn-outline-info text-uppercase rounded-pill text-uppercase fs-5 fw-semibold px-5 py-3"
                >Activate Subscription</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  name: "PaymentStatus",
  components: {
    StripeCheckout,
  },
  created() {
    this.value = localStorage.getItem("pay_email");
    let role = localStorage.getItem("role");
    this.id = localStorage.getItem("id");
    let st = localStorage.getItem("pay_status");
    let st1 = localStorage.getItem("used");
    this.pay = st1;
    // console.log('st',st1)
    if (st != 0 && role == "User") {
      this.$router.push(this.defaultUrl);
    } else if (st != 0 && role == "Admin") {
      this.$router.push("/admin/users");
    }
  },
  destroyed() {
    localStorage.clear();
  },
  data() {
    return {
      pay: "",
      value: "",
      id: "",
      publishableKey: process.env.VUE_APP_PUBLISHABLE_KEY,
      loading: false,
      defaultUrl: process.env.VUE_APP_DEFAULT_ROUTE,
      lineItems: [
        {
          price: process.env.VUE_APP_PRICE_WITH_TRAIL, // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      lineItems1: [
        {
          price: process.env.VUE_APP_PRICE_WITHOUT_TRAIL, // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: `${process.env.VUE_APP_BASEURLFRONT}success?session_id={CHECKOUT_SESSION_ID}`,
      cancelURL: `${process.env.VUE_APP_BASEURLFRONT}cancel?session_id={CHECKOUT_SESSION_ID}`,
      subscriptionPrice:process.env.VUE_APP_SUBSCRIPTION_PRICE,
      subscriptionTrialDays:process.env.VUE_APP_SUBSCRIPTION_TRIAL_DAYS,
      currency:`${process.env.VUE_APP_CURRENCY}`
    };
  },
  methods: {
    goToPayment() {
      if(this.pay == 1) {
        window.location.href = process.env.VUE_APP_STRIPE_PAYEMENT_LINK+"?prefilled_email="+this.value
      }
      else{
        window.location.href = process.env.VUE_APP_STRIPE_PAYEMENT_TRIAL_LINK+"?prefilled_email="+this.value
      }
      //this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>
