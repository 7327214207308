import Vue from 'vue'
import Vuex from 'vuex'
import feedsModules from '../store/modules/feedsModule'
import usersModules from '../store/modules/usersModule'
import monthlyFeedModules from '../store/modules/monthlyFeeds'
import businessModule from '../store/modules/businessModule'
import settingsModule from '../store/modules/settingsModule'
import tagsCategoriesModule from '../store/modules/tagsCategoriesModule'
import tagsModule from './modules/tagsModule'
import smartTagsModule from './modules/smartTagsModule'
import reviewsModule from './modules/reviewsModule'
import postTemplatesModule from './modules/postTemplatesModule'
import userReviewPostSettingModule from './modules/userReviewPostSettingModule'
import widgetsModule from './modules/widgetsModule'
import getStartedModule from './modules/getStarted'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    feedsModules: feedsModules,
    usersModules: usersModules,
    monthlyFeedModules: monthlyFeedModules,
    businessModule: businessModule,
    settingsModule: settingsModule,
    tagsCategoriesModule: tagsCategoriesModule,
    tagsModule: tagsModule,
    smartTagsModule: smartTagsModule,
    reviewsModule:reviewsModule,
    postTemplatesModule:postTemplatesModule,
    userReviewPostSettingModule:userReviewPostSettingModule,
    widgetsModule:widgetsModule,
    getStartedModule:getStartedModule
  }
})
