<template>
  <!-- navigation -->
  <nav class="navbar navbar-expand-lg navbar-light py-2">
    <div class="container">
      <a class="navbar-brand" :href="homeUrl"
        ><img src="/images/logo.png" alt=""
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse py-4" id="navbarTogglerDemo02">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item px-4">
            <a
              class="nav-link text-center custom-nav-link text-uppercase px-0 "
              :href="homeUrl"
              >Home</a
            >
          </li>
          <li class="nav-item px-4">
            <a
              class="nav-link text-center custom-nav-link text-uppercase px-0 "
              :href="blogUrl"
              >Blog</a
            >
          </li>
          <li class="nav-item px-4">
            <a
              class="nav-link text-center custom-nav-link text-uppercase px-0"
              :href="contactUrl"
              >Contact</a
            >
          </li>
        </ul>
        <form class="d-flex justify-content-center">
          <router-link
            v-if="userLoggedIn == false"
            class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-5 py-2"
            aria-current="page"
            to="/login"
            >Login</router-link
          >
          <router-link
            v-if="userLoggedIn == false"
            class="btn btn-info text-white rounded-pill text-uppercase fw-bold ms-2 px-5 py-2"
            aria-current="page"
            to="/signup"
            >Signup</router-link
          >
          <router-link
            v-if="userLoggedIn == true && payStatus == 1"
            class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-5"
            aria-current="page"
            to="/scheduled-post-history"
            >Content Calendar</router-link
          >
          <a href="javascript:;"
            v-if="userLoggedIn == true"
            @click="logout"
            class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-5"
            aria-current="page"
          >Logout</a>
        </form>
      </div>
    </div>
  </nav>
  <!-- /navigation -->
</template>
<script>
import axios from 'axios';
export default {
  name: "FrontHeader",
  data() {
    return {
      userLoggedIn: false,
      payStatus: `${localStorage.getItem('pay_status')}`,
      userName: null,
      homeUrl: process.env.VUE_APP_PROMOTIONAL_PAGE_URL,
      contactUrl: process.env.VUE_APP_PROMOTIONAL_PAGE_URL + "contact.html",
      blogUrl: process.env.VUE_APP_PROMOTIONAL_PAGE_URL + "Blog",
    };
  },
  methods: {
    logout() {
          let obj = ''
          axios
              .post(process.env.VUE_APP_BASEURL + '/logout', obj, {
                  headers: {
                      'Content-type': 'application/json',
                      token: `${localStorage.getItem('token')}`,
                  },
              })
              .then(() => {})
              .catch(() => {})
              localStorage.clear()

          this.$router.push('/login')
      },
      checkLoggedIn:function(){
        if (localStorage.token) {
          this.userLoggedIn = true;
          this.userName = localStorage.name;
          //          let st = localStorage.getItem("pay_status");
          // if (st == 0) {
        } else {
          this.userLoggedIn = false;
        }
      }
  },
  watch: {
      $route(to, from) {
          this.checkLoggedIn();
      }
  },
  mounted() {
    this.checkLoggedIn();
  },
};
</script>

<style scoped>
@import "../../assets/css/custom.css";
</style>
