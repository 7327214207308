<template>
    <div class="container mt-4">
        <div class="d-flex justify-content-end">
            <div class="w-50">
                <h4 class="m-1">
                    Widgets
                </h4>
            </div>
            <div class="w-50">
                <div class="d-flex justify-content-end">
                    <router-link
                        to="/reviews"
                        class="text-white btn-sm btn btn-primary m-2 d-none"
                    >
                    <i class="bi bi-caret-left-fill"></i>
                        Back
                    </router-link>
                    <router-link
                        to="/widget/add"
                        class="text-white btn-sm btn btn-primary m-2"
                    >
                        Create Widget
                    </router-link>
                </div>
            </div>
        </div>
        <vue-good-table
            mode="remote"
            @on-search="onSearch"
            @on-sort-change="onSortChange"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            :rows="listing"
            :columns="columns"
            :isLoading="isLoading"
            :totalRows="totalRows"
            :sort-options="{
                enabled: true,
                initialSortBy: { 
                    field: 'id',
                    type: 'desc'
                },
            }"
            :search-options="{ 
                enabled: true,
                placeholder: 'Search',
            }"
            :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                perPageDropdown: [
                    10,
                    20,
                    30,
                    40,
                    50
                ],
                perPage: 10,
                mode: 'pages',
            }"
        >
            <template slot="table-row" slot-scope="props">
                {{
                    props.column.field == 'created_at' ? dateFormat(props.formattedRow.created_at) : ''
                }}

                {{
                    props.column.field == 'title' ? props.formattedRow.title : ''
                }}

                <template v-if="props.column.field == 'status'">
                    <div class="form-check form-switch">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="props.formattedRow.status"
                            @change="(e) => onStatusChange(e, props.row)"
                        />
                    </div>
                </template>
                <template v-if="props.column.field == 'actions'">
                    <button
                        class="btn btn-primary m-1"
                        @click="setScript(props.row.unique_id, props.row.title)"
                    >
                        <i class="bi bi-clipboard2"></i>
                    </button>
                    <router-link
                        class="btn btn-success m-1 text-white"
                        :to="`/widget/update/${props.row.unique_id}`"
                    >
                        <i class="bi bi-pencil"></i>
                    </router-link>
                    
                    <button
                        class="btn btn-danger open_delete_modal"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        :data-id="props.row.id"
                        @click="onOpenDeleteModal(props.row.id)"
                    >
                        <i class="bi bi-trash-fill"></i>
                    </button>
                </template>
            </template>
        </vue-good-table>
        
		<div class="modal fade" id="deleteModal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="deleteModalLabel">
                            <i class="bi bi-person-x m-1"></i> Delete Record
                        </h5>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure, you want to delete?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-danger delete_record_btn" data-id="" @click="deleteRecord" data-bs-dismiss="modal">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="viewCode">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <i class="bi bi-x-lg close-icon" data-bs-dismiss="modal"></i>
                    <div class="p-2">
                        <div class="mb-2">
                            <i class="bi bi-copy"></i>
                            <label for="" class="form-label">
                                <b>Copy this code and paste in the webpage</b>
                            </label>
                            <textarea
                                class="form-control"
                                style="border-style: dashed"
                                disabled
                                rows="5"
                                >
                                {{ scriptCode }}
                            </textarea>
                        </div>
                        <div class="d-flex justify-content-center">
                            <a
                                href="javascript:;"
                                @click="copyCodeToClipboard"
                                style="text-decoration: none"
                                >
                                <i class="bi bi-clipboard2"></i>
                                Click to copy the code
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import moment from 'moment-timezone'

export default {
    name: 'tags',
    components: {
        vSelect,
    },
    data() {
        return {
            columns: [
                {
                    label: 'Title',
                    field: 'title',
					width: '25%',
                },
                {
                    label: 'id',
                    field: 'unique_id',
                    hidden:true
                },
                {
                    label: 'Status',
                    field: 'status',
                    sortable: false,
					width: '20%',
                },
                {
                    label: 'Created Date',
                    field: 'created_at',
                    width: '30%',
                },
                {
                    label: 'Action',
                    field: 'actions',
                    sortable: false,
					width: '25%',
                    globalSearchDisabled: true,
                }
            ],
            listing:[],
            totalRows:null,
            isLoading:true,
            scriptTemplate:'',
            scriptCode:'',
            perPage:10
        }
    },
    computed: {},
    created: function(){
        this.isLoading = true
        this.fetchListing();

        this.getSetting('widget_script_code_template').then((resp) => {
            if(resp)
            {
                this.scriptTemplate = resp.widget_script_code_template  ? resp.widget_script_code_template : ''
            }
        })
    },
    methods: {
        ...mapActions([
            'deleteUserWidget',
            'getUserWidgets',
            'getSetting',
            'updateUserWidgetStatus'
        ]),
        onPageChange: function (data){
            this.fetchListing(data.currentPage)
        },
        onPerPageChange: function (data){
            this.perPage = data.currentPerPage
            this.fetchListing(data.currentPage, data.currentPerPage)
        },
        onSortChange: function (data){
            this.fetchListing(1, '', data[0].field, data[0].type)
        },
        onSearch: function (data){
            this.fetchListing(1,'','','',data.searchTerm)
        },
        fetchListing: function(page = 1, limit = '', field = '', direction = '', search = ''){
            this.isLoading = true
            direction = direction != 'none' ? direction : '';
            limit = !limit ? this.perPage : limit
            this.getUserWidgets({page, limit, field, direction, search}).then((resp) => {
                this.totalRows = resp.data.total
                this.listing = resp.data.data
                this.isLoading = false
            }).catch(() => {
                console.log(" Something went wrong please try later ")
            })
        },
        deleteRecord: async function(e){
            let id = e.target.getAttribute('data-id');
            let resp = await this.deleteUserWidget(id)
            if(resp && resp.status)
            {
                let deleteBtn = document.querySelector(`button.open_delete_modal[data-id='${id}']`); 
                deleteBtn.parentElement.parentElement.remove()
                this.notify(resp.message)
            }
            else
            {
                this.notify(resp.message,'danger')
            }
        },
        onOpenDeleteModal: function(id){
            let deleteModal = document.querySelector('div#deleteModal')
            if(deleteModal)
            {
                deleteModal.querySelector('button.delete_record_btn').setAttribute('data-id', id)
            }
        },
        notify:function(msg, type = 'success'){
            if(type)
            {
                this.$notify(msg, type)
            }
            else
            {
                this.$notify(msg)
            }
        },
        onStatusChange:function(e, row){
            let temp = {
                id:row.id,
                status:e.target.checked ? 1 : 0
            }
            
            this.updateUserWidgetStatus(temp).then((resp) => {
                if(resp.status)
                {
                    this.notify(resp.message)
                }
                else
                {
                    this.notify(resp.message ? resp.message : 'something went wrong please try later' ,'danger')
                }
            })
        },
        copyCodeToClipboard:function(){
            navigator.clipboard.writeText(this.scriptCode)
            this.notify('Code copied to clipboard ')
        },
        dateFormat:function(date){
            return date ? moment(date).format('MM-DD-YYYY hh:mm A') : '--'
        },
        setScript: function (uniqueId, title = '') {
            this.scriptCode = this.scriptTemplate.replace(
                '{widget_unique_id}',
                uniqueId
            )

            this.scriptCode = this.scriptCode.replace(
                '{widget_title}',
                title
            )
            $('body').find('#viewCode').modal('show')
        },
    }
}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

/*a {
    color: #42b983;
}*/

.form-switch .form-check-input {
    width: 50px !important;
    height: 2rem !important;
}
.close-icon {
	position: absolute;
	top: 10px;
	right: 10px;
}

.openModalBusiness { 
	cursor: pointer;
}

.rounded-circle{
    content: "";
    position: absolute;
    top: -1px;
    right: -3px;
    width: 8px;
    height: 8px;
}
</style>