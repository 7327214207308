<template>
  <div class="col-6 mx-auto card mt-5 my-5 p-5 shadow-lg border rounded">
    <div class="h3">Add New User</div>
    <hr />
    <form ref="anyName" class="mt-1" @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="fname" class="form-label"
          >First Name <span class="required">*</span></label
        >
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.formFields.first_name.$error }"
          v-model="formFields.first_name"
          id="fname"
          placeholder="Enter First Name"
        />
        <!-- <span v-if="v$.first_name.$error ">{{v$.first_name.$errors[0].$message}}</span> -->
      </div>
      <div class="mb-3">
        <label for="lname" class="form-label"
          >Last Name <span class="required">*</span></label
        >
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.formFields.last_name.$error }"
          id="lname"
          v-model="formFields.last_name"
          placeholder="Enter Last Name"
        />
        <!-- <span v-if="v$.last_name.$error ">{{v$.last_name.$errors[0].$message}}</span> -->
      </div>
      <div class="mb-3">
        <label for="email" class="form-label"
          >Email <span class="required">*</span></label
        >
        <input
          type="email"
          class="form-control"
          :class="{ 'is-invalid': $v.formFields.email.$error }"
          id="email"
          v-model="formFields.email"
          placeholder="Enter Email"
          autocomplete="username"
        />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label"
          >Password <span class="required">*</span></label
        >
        <input
          type="password"
          class="form-control"
          :class="{ 'is-invalid': $v.formFields.password.password.$error }"
          id="password"
          v-model="formFields.password.password"
          placeholder="Enter Password"
          autocomplete="new-password"
        />
      </div>
      <div class="mb-3">
        <label for="cpassword" class="form-label"
          >Confirm Password <span class="required">*</span></label
        >
        <input
          type="password"
          class="form-control"
          :class="{
            'is-invalid': $v.formFields.password.confirmPassword.$error,
          }"
          id="cpassword"
          v-model="formFields.password.confirmPassword"
          placeholder="Confirm Password"
          autocomplete="new-password"
        />
        <!-- <span v-if="v$.password.confirmPassword.$error">Must be same as password</span> -->
      </div>
      <div class="mb-3">
        <label for="pno" class="form-label"
          >Phone Number <span class="required">*</span></label
        >
        <input
          type="number"
          class="form-control"
          :class="{ 'is-invalid': $v.formFields.phone.$error }"
          id="pno"
          v-model="formFields.phone"
          placeholder="Enter Phone Number"
        />
      </div>
      <div class="mb-3">
        <label for="cmp_name" class="form-label"
          >Company Name <span class="required">*</span></label
        >
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.formFields.companyName.$error }"
          id="cmp_name"
          v-model="formFields.companyName"
          placeholder="Enter Company Name"
        />
      </div>
      <div class="mb-3">
        <label for="cmp_website" class="form-label"
          >Company Website <span class="required">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="cmp_website"
          :class="{
            'is-invalid':
              parseUrl == false || $v.formFields.companyWebsite.$error,
          }"
          v-model="formFields.companyWebsite"
          placeholder="Company Website"
        />
        <span
          class="text-danger"
          style="font-size: 11px"
          v-if="parseUrl == false"
          >Enter a valid url</span
        >
      </div>
      <div class="mb-3">
        <label for="address" class="form-label">Address</label>
        <input
          type="text"
          class="form-control"
          id="address"
          v-model="formFields.address"
          placeholder="Enter Address"
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="country"
          >Country <span class="required">*</span></label
        >
        <v-select
          label="name"
          v-model="formFields.country"
          @input="updateTimeZones"
          id="country"
          placeholder="select country"
          :class="{
            'is-invalid': $v.formFields.country.$error,
          }"          
          :options="options"
        ></v-select>
      </div>
      <div class="mb-3">
        <label class="form-label" for="Time Zone"
          >Select Time Zone <span class="required">*</span></label
        >
        <v-select
          id="timezone"
          v-model="formFields.time_zone"
          :options="timeZones"         
            :class="{
            'is-invalid': $v.formFields.time_zone.$error,
          }"          
          placeholder="Select a time zone"
        ></v-select>
      </div>
      <div class="mb-3">
        <label for="city" class="form-label"
          >City<span class="required">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="city"
          v-model="formFields.city"
          :class="{
            'is-invalid': $v.formFields.city.$error,
          }"
          placeholder="Enter City"
        />
      </div>
      <div class=" mb-2">
        <label class="form-check-label m-b-10" for="oIs Onboarding Complete">
          Is Onboarding Complete?
        </label>        
            <select class="form-select" v-model="formFields.is_onboarding">
              <option value="0">No</option>
              <option value="1">Yes</option>
          </select>
      </div>
      <div class="row">

        <div class="col-sm-6 mt-2 mb-2">
          <label class="form-check-label m-b-10" for="sub_status">
            Subscription Status
          </label>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              v-model="pay_status"
              type="checkbox"
              id="sub_status"
            />
          </div>
        </div>
      <div class="col-sm-6 mt-2 mb-2">
        <label class="form-check-label m-b-10" for="sub_status">
           Enable Monthly Feed
        </label>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="monthly_feed"
            type="checkbox"
            id="is_monthly_feed"
          />
        </div>
      </div>
      <div class="col-sm-6 mt-2 mb-2">
        <label class="form-check-label m-b-10" for="fetch_reviews_enable">
          Fetch Review Status
        </label>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="formFields.fetch_reviews_enable"
            type="checkbox"
            id="fetch_reviews_enable"
          />
        </div>
      </div>
	  <div class="col-sm-6 mt-2 mb-2">
        <label class="form-check-label m-b-10" for="auto_post_status">
			Auto Review Posting
        </label>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            v-model="formFields.auto_post_status"
            type="checkbox"
            id="auto_post_status"
          />
        </div>
      </div>       
      </div>
      <div class="mb-3 mt-5">
        <button
          class="btn btn-primary"
          @click="submitForm"
          type="button"
          :class="isSubmitted ? 'disabled' : ''"
        >
          <span
            v-if="isSubmitted"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Submit
        </button>
        <router-link
          style="text-decoration: none"
          class="text-white"
          aria-current="page"
          to="/admin/users"
        >
          <button class="btn btn-secondary m-3" type="button">Cancel</button>
        </router-link>
      </div>
    </form>
    <stripe-checkout
      ref="checkoutRef"
      mode="subscription"
      :pk="publishableKey"
      :customerEmail="value"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
    />
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import {
  required,
  email,
  url,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import { countryTimeZones } from './timezoneData';
import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;
export default {
  name: "AddUser",
  components: {
    vSelect,
    StripeCheckout,
  },
  created() {
    this.publishableKey = "pk_test_wFcmPCTnRCsCkJwNrLivZ4za";
  },

  data() {
    return {     
      timeZones: [],
      parseUrl: true,
      value: null,
      id: "",
      pay_status: false,
      monthly_feed: false,
      isSubmitted: false,
      options: [
        { name: "Canada", code: "CA" },
        { name: "Australia", code: "AU" },
        { name: "United States", code: "US" },
        { name: "United Kingdom", code: "UK" },
        { name: "Other", code: "other" },
      ],
      formFields: {
        first_name: "",
        last_name: "",
        email: "",
        password: {
          password: "",
          confirmPassword: "",
        },
        phone: "",
        companyName: "",
        companyWebsite: "",        
        address: "",
        country: "",
        time_zone:"",
        city: "",
        fetch_reviews_enable: '',
        is_onboarding:0,
		auto_post_status:0
      },
      loading: false,
      lineItems: [
        {
          price: "price_1KiFfJJHXXI4anPxPxBJ2Stn", // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: `${process.env.VUE_APP_BASEURLFRONT}success?session_id={CHECKOUT_SESSION_ID}`,
      cancelURL: `${process.env.VUE_APP_BASEURLFRONT}cancel?session_id={CHECKOUT_SESSION_ID}`,
    };
  },
  validations: {
    formFields: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      password: {
        password: { required, minLength: 6 },
        confirmPassword: {
          required,
          minLength: 6,
          sameAsPassword: sameAs(function () {
            return this.formFields.password.password;
          }),
        },
      },
      phone: { required },
      companyName: { required },
      companyWebsite: { required },
      address: "",
      country: { required },
      time_zone: { required },
      city: { required },
    },
  },
  mounted:function(){
    let removeEventListener = function(){
      document.removeEventListener("contextmenu",window.$disableRightClick, false);
    } 
    window.onload = removeEventListener
    removeEventListener()
  },
  beforeRouteLeave:function(to, from , next) {
    document.addEventListener("contextmenu",window.$disableRightClick, false);
    next()
  },
  methods: {
    updateTimeZones() { 
      this.timeZones = countryTimeZones[this.formFields.country.name];      
      this.selectedTimeZone = ""; 
    },
    onSelect(data) {
      // console.log("select", data);
    },
    onChange() {
      //  console.log("cdsfdsf");
    },
    resetForm() {
      var self = this; //you need this because *this* will refer to Object.keys below`

      //Iterate through each object field, key is name of the object field`
      Object.keys(this.formFields).forEach(function (key, index) {
        self.formFields[key] = "";
      });
    },
    parseLink(href) {
      var res = href.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    submitForm(e) {
      e.preventDefault();
      //localStorage.clear();
      this.value = this.formFields.email;

      if (this.formFields.companyWebsite != "") {
        this.parseUrl = this.parseLink(this.formFields.companyWebsite);

        if (this.parseUrl == false) {
          return false;
        }
      }
      this.$v.$touch();
      this.isSubmitted = true;
      var temp = {
        first_name: this.formFields.first_name,
        last_name: this.formFields.last_name,
        email: this.formFields.email,
        password: this.formFields.password.password,
        company: this.formFields.companyName,
        phone: this.formFields.phone,        
        country: this.formFields.country.name
          ? this.formFields.country.name
          : this.formFields.country,
        time_zone: this.formFields.time_zone,
        city: this.formFields.city,
        address: this.formFields.address,
        website: this.formFields.companyWebsite,
        password_confirmation: this.formFields.password.confirmPassword,
        pay_status: this.pay_status,
        is_monthly_feed: this.monthly_feed,
        fetch_reviews_enable: this.formFields.fetch_reviews_enable,
        auto_post_status: this.formFields.auto_post_status,
        is_onboarding: this.formFields.is_onboarding,
      };
      this.$v.$touch();
      if (!this.$v.$invalid) {
        axios
          .post(process.env.VUE_APP_BASEURL + "/dashboard/addUser", temp, {
            headers: {
              "Content-type": "application/json",
              token: `${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            this.loading = true;
            this.isSubmitted = false;
            // this.resetForm()
            var success_msg = "User added successfully";
            if (this.pay_status == 1 ) {
              success_msg = "Subscribed user added successfully";
            }
            this.$toasted.show(`${success_msg}`, {
              theme: "bubble",
              type: "success",
              position: "top-center",
              duration: 2000,
              singleton: true,
            });
            this.$router.push("/admin/users");
          })
          .catch((err) => {
            this.isSubmitted = false;

            if (err?.response?.status == 400) {
              this.$toasted.show(
                `${err?.response?.data?.error_message.email[0]}`,
                {
                  theme: "bubble",
                  type: "error",
                  position: "top-center",
                  duration: 2000,
                  singleton: true,
                }
              );
            } else {
              this.$toasted.show(`Internal Server Error`, {
                theme: "bubble",
                type: "error",
                position: "top-center",
                duration: 2000,
                singleton: true,
              });
            }
          });
      } else {
        this.isSubmitted = false;
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.show-error {
  border: 1px solid red;
}
.required {
  color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.form-switch .form-check-input {
  width: 50px !important;
  height: 2rem !important;
}
</style>
