<template>
    <div>
        <div class="page-content page-container" id="page-content">
            <div class="padding container">
                <div class="row d-flex justify-content-center">
                    <div class="col-xl-12 col-md-12">
                        <div class="d-flex justify-content-between position-relative">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <router-link  class="nav-link" aria-current="page" style="color: #0d6efd !important;" to="/business">
                                        Business
                                    </router-link>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <router-link 
                                        class="nav-link"
                                        style="color: #0d6efd !important;"
                                        to="/post-templates">
                                        Review Post Settings
                                    </router-link>
                                </li>
                                <!-- <li class="nav-item" role="presentation">
                                    <router-link class="nav-link" style="color: #0d6efd !important;" to="/widgets">
                                    Widgets
                                    </router-link>
                                </li> -->
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" type="button">
                                        Upload Logo
                                    </button>
                                </li>
                            </ul>                            
                        </div>
                        
                        <div class="tab-content">
                            <div class="tab-pane active">
                                <div class="card user-card-full">
                                    <div class="d-flex justify-content-between mt-3 ms-3">
                                        <h5 class="mt-2 pb-2 f-w-600">
                                            Upload Logo
                                        </h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr class="m-0">
                                        </div>
                                    </div>
                                    <div class="row gx-0">
                                        <div class="d-flex justify-content-center align-items-center" style="min-height: 50vh;">
                                            <div class="d-flex-column justify-content-center align-items-center" style="padding: 20px 150px;border-radius: 4px;background-color: #f3f3f3;">
                                                <div class="d-flex justify-content-center mb-3 w-100">
                                                    <img
                                                        v-if="customLogo"
                                                        :src="showUploadedLogo(customLogo)"
                                                        style="cursor: pointer;"
                                                        class="popup-logo img-thumbnail shadow has-tooltip p-2 border-2" :style="{ 'border-color': colour }"
                                                        alt="logo"
                                                        @click="showImgInPopup(showUploadedLogo(customLogo))"
                                                    />
                                                </div>
                                                <p class="mb-4">Upload Your Logo (We Will Apply Your Logo To Social Feeds)</p>
                                                <div class="d-flex justify-content-center">
                                                    <a
                                                        href="javascript:;"
                                                        v-if="skipLogo == true || !showStep2"
                                                        class="btn btn-cus-primary px-2 py-1 d-inline mt-3"
                                                        @click="openUploadLogo"
                                                        v-tooltip="'Upload logo for social feeds'"
                                                    >
                                                        <i class="bi bi-upload me-1"></i>
                                                        Upload logo
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div
        class="modal fade"
        tabindex="-1"
        id="Image-upload"
        data-bs-backdrop="static"
        >
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header py-3 w-100">
                <h3 class="modal-title w-100 text-center">Select your logo</h3>
            </div>
            <div class="progress" v-if="selectedFile">
                <div
                class="progress-bar bg-success"
                role="progressbar"
                v-bind:style="{ width: length }"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                ></div>
            </div>
            <div class="modal-body position-relative">
                <img
                v-tooltip="'uploaded logo'"
                v-if="customLogo"
                :src="showUploadedLogo(customLogo)"
                class="popup-logo position-absolute shadow has-tooltip p-2 border-2" :style="{ 'border-color': colour }"
                alt="logo"
                />
                <form>
                <div class="row justify-content-center pt-3">
                    <label
                    class="hoverable profile-input p-0 m-0"
                    style="width: 31%; height: 31%"
                    for="fileInput"
                    >
                    <img
                        v-if="imgSrc"
                        :src="imgSrc"
                        class="img-fluid rounded-circle selected-logo w-100 h-100"
                    />
                    <img
                        v-if="!imgSrc"
                        src="../../../assets/old_logo.png"
                        class="img-fluid rounded-circle w-100 h-100 object-fit-cover"
                    />
                    <div class="hover-text">Choose file</div>
                    <div class="background"></div>
                    </label>
                    <br />

                    <input
                    id="fileInput"
                    type="file"
                    @change="readURL($event)"
                    accept="image/*"
                    />
                    <div class="mt-4 mb-2">
                        <p class="text-danger mx-auto m-0 mb-3" style="width: fit-content; font-size: 12px">
                            The image format should be .png, .jpg, or .jpeg,<br/> with a recommended size of 250x250 pixels.
                        </p>
                    <p
                        class="text-dark mx-auto border-bottom border-2 border-dark m-0 h3"
                        style="width: fit-content; font-size: 12px"
                    >
                        Background Color Behind Logo (Optional)
                    </p>
                    </div>
                    <div
                    class="d-flex align-items-center justify-content-center pt-3 flex-wrap"
                    >
                    <label for="head" class=""
                        ><strong>Pick Color</strong> :&nbsp;&nbsp;
                    </label>
                    <input
                        type="color"
                        id="head"
                        name="head"
                        @change="changecoloroption()"
                        v-model="colour"                    
                        class="form-control form-control-color p-0 border-dark"
                    />
                    </div>
                    <small class="text-center py-2"
                    >The background color will only be applied if the logo is configured to appear below the feed image <a :href="dummyImage" target="_blank">( like this )</a>. This setting is determined automatically based on the feed's configuration.</small
                    >
                </div>
                <div class="card-footer col-12 border-0 mt-3 text-center py-2">
                    <button
                    type="button"
                    class="btn btn-secondary btn-sm me-3"
                    data-bs-dismiss="modal"
                    :class="isLoad ? 'disabled' : ''"
                    @click="cancelImageUpload()"
                    >
                    Cancel
                    </button>
                    <button
                    type="button"
                    :class="isLoad ? 'disabled' : ''"
                    class="btn btn-success btn-sm"                  
                    @click="uploadLogo()"
                    >
                    Save & Upload
                    <span
                        v-if="isLoad"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    </button>
                </div>
                </form>
            </div>
            </div>
        </div>
        </div>

        <div class="modal fade" :id="`showImgInPopup`" tabindex="-1" aria-labelledby="showImgInPopupLabel" aria-hidden="true" >
            <div class="modal-dialog modal-lg">
                <div class="modal-content bg-transparent border-0">
                    <div class="modal-body px-0" style="width: fit-content; margin: 0 auto; z-index: 0">
                        <div class="popup-img-div">
                            <div class="popup-d w-fit-content m-auto position-relative">
                                <button type="button" data-bs-dismiss="modal" aria-label="Close" class="close btn btn-close custom-btn bg-white p-3"></button>
                                <img class="h-auto img-fluid" :src="showImgInPopUpSrc" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import Loading from "vue-loading-overlay";

export default {
  name: "MyProfile",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      customLogo: "",
      isLoading: false,
      subData: {},
      loading: false,
      imgSrc: "",
      selectedFile: "",
      length: "",
      isLoad: false,
      colour: "#ffffff",
      servercolour: "",
      colourchanged: 0,
      userData: [],
      showImgInPopUpSrc:'',
      skipLogo: false,
      showStep2: false,
      dForm: {
        reason: "",
      },
      dummyImage:''
    };
  },
  computed: { ...mapGetters(["userList"]) },
  created() {
    this.dummyImage = process.env.VUE_APP_DUMMY_LOGO_SET_IMAGE;
    this.isLoading = true;
    let id = localStorage.getItem("id");
    this.fetchUser(id)
      .then(() => {
        this.loading = false;
        this.colour =  this.userList?.user_data?.color_code;
        this.servercolour = this.userList?.user_data?.color_code;
        this.customLogo = this.userList?.user_data?.custom_logo;
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
    });
  },

  methods: {
    ...mapActions(["fetchUser"]),
    submitForm(e) {
      e.preventDefault();

      this.$v.$touch();
      // console.log(this.formFields);
      if (!this.$v.$invalid) {
        this.editUser();
        //alert("form submitted Successfully");
      } else {
        this.loading = false;
        // alert("form submission failed");
      }
    },
    changeoloroption(){
      this.colourchanged = 1;
    },
    changecoloroption(){
      this.colourchanged = 1;
    },
    showImgInPopup(img) {
        this.showImgInPopUpSrc = img;
        $(`#showImgInPopup`).modal("show");
    },
    readURL(event) {
      	if(event.target.files && event.target.files[0])
	  	{
        	this.selectedFile = event.target.files[0];

			if(this.selectedFile.type == 'image/png' || this.selectedFile.type == 'image/jpg' || this.selectedFile.type == 'image/jpeg')
			{
				var reader 	= new FileReader();
				reader.onload = (event) => {
					this.imgSrc = event.target.result;
				};
				reader.readAsDataURL(event.target.files[0]);
			}
			else
			{
				this.$toasted.show("The image format should be .png, .jpg, or .jpeg.", {
					theme: "bubble",
					type: "error",
					position: "top-center",
					duration: 2500,
					singleton: true
				});
			}
		}
    },
    cancelImageUpload() {      
      $("#fileInput").val("");
      this.selectedFile = "";
      this.imgSrc = "";

      if ( this.servercolour != '' && this.colour != this.servercolour ){
          this.colour = this.servercolour;
      }      
    },
    uploadLogo() {              
      if ( this.colourchanged == 0 && this.selectedFile == ""){
        let uploadmsg = "Logo is required";
        if (this.customLogo != "") {
           uploadmsg = "Either upload logo or change color before save";
        }
        this.$toasted.show(uploadmsg, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2500,
              singleton: true,
            });
        return;
      }
      this.length = "25%";
      this.isLoad = true;
      let data = new FormData();
      data.append("file", this.selectedFile);
      data.append("apply_to", true);
      data.append("color_code", this.colour);
      
      axios
        .post(process.env.VUE_APP_BASEURL + "/upload-user-logo", data, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.length = "50%";
          }, 1000);
          setTimeout(() => {
            this.length = "75%";
          }, 2000);
          setTimeout(() => {
            this.length = "100%";
            this.customLogo = res?.data?.logo;
          }, 3000);
          this.refresh++;
          //console.log( this.refresh)
          setTimeout(() => {
            this.showStep2 = false;
            this.skipLogo = false;
            this.isLoad = false;
            this.length = "0%";            
            this.imgSrc = "";
            let msg = 'Logo uploaded successfully';
            if ( this.selectedFile == "" ){
                msg = 'Color updated successfully';
            }
            this.selectedFile = "";
            this.$toasted.show(msg, {
              theme: "bubble",
              type: "success",
              position: "top-center",
              duration: 1500,
              singleton: true,
            });
          }, 3200);
          setTimeout(() => {
            $("#Image-upload").modal("hide");
            $("#fileInput").val("");
            this.servercolour = '';
            //$("#head").val("#ffffff");
            //this.colour = "#ffffff";
          }, 3600);
        })
        .catch((err) => {
          //console.log('errr',err)
          this.length = "0%";
          this.isLoad = false;
          this.selectedFile = "";
          if (err?.response?.data?.status == false) {
            this.$toasted.show(`${err?.response?.data?.error_message}`, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2500,
              singleton: true,
            });
          } else {
            this.$toasted.show(`Internal Server Error`, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 2500,
              singleton: true,
            });
          }
        });
    },
    showUploadedLogo(logo) {
      return (
        process.env.VUE_APP_BASEUPLOADURL +
        logo +
        "?" +
        Math.floor(Math.random() * 10000000)
      );
    },
    openUploadLogo() {
      $(`#fileInput`).val("");
      $("#Image-upload").modal("show");
    },
  },
};
</script>

<style>
body {
    background-color: #f9f9fa;
}

.padding {
    padding: 3rem !important;
}

.user-card-full {
    overflow: visible !important;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

@media (min-width: 992px) {
    .user-card-full .user-profile-div {
        border-radius: 5px 0 0 5px;
    }
}

.bg-c-lite-green {
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#f29263),
        to(#ee5a6f)
    );
    background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.required {
    color: red;
}
.popup-logo {
    max-height: 120px;
    image-rendering: -webkit-optimize-contrast;
    max-width: 100px;
    height: auto;
    width: auto;
    top: 6px;
    border: solid;
}
.selected-logo {
    object-fit: contain;
    max-width: 100% !important;
    max-height: 149px;
    min-width: 149px;
    min-height: 149px;
}

.back-to-account-btn{
    top: 0;
}

@media screen and (max-width: 540px) {
    .back-to-account-btn {
        top:-29px !important
    }
}
</style>