<template>
    <div class="modal fade"  id="reMonthlyFeedScheduleModal" tabindex="-1" aria-labelledby="reMonthlyFeedScheduleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="monthlyFeedScheduleModalLabel">Re-Schedule Form</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
                </div>
                <!-- Loading Spinner -->
                <div class="d-flex justify-content-center align-items-center h-100 w-100 position-absolute" v-if="isLoading">
                    <span class="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span>
                    Loading..
                </div>
                <!-- Clients List -->
                <div v-else>
                    <div class="modal-body" >
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <h5 class="mb-0">List of Clients</h5>
                            <div class="d-flex align-items-right">
                                <input type="text" class="form-control w-50 me-4" v-model="clientSearch" placeholder="Search"  @input="getClients()">
                                <h5 class="mb-0">Total Clients: <span class="badge bg-info small">{{ clientData.length }}</span></h5>
                            </div>
                        </div>
                        <div class="card-body" >
                            <div class="clients-list-grp" v-if="clientData.length > 0">
                                <ul class="list-group">
                                    <li 
                                        class="list-group-item d-flex justify-content-between align-items-start" 
                                        v-for="(client, index) in clientData" 
                                        :key="index"
                                    >
                                    <div class="ms-2 me-auto">
                                        <div>
                                            {{ client.id }} <strong>[{{ client.first_name }}]</strong>
                                        </div>
                                        <span :class="client.ayr_connected_accounts ? 'badge bg-success' : 'badge bg-danger'">
                                            {{ client.ayr_connected_accounts ? 'Accounts connected' : 'No connected accounts' }}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- No Clients Found -->
                        <div class="clients-list-grp" v-else>
                            <span>No Clients Found!</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button 
                        v-if="arsConnected"
                        @click="saveScheduleFeed" 
                        class="btn btn-primary"
                        :disabled="!isSubmitted"
                    >
                        Re-Schedule
                    </button>
                    <button
                        class="btn btn-danger"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";  
import axios from 'axios';

export default {
    name: "RescheduleMonthlyFeeds",
    components: {
        Loading,
        vSelect,
        axios
    },
    props: {
        scheduleFeedItem: {
            type: Array
        },
        filterData: {
            type: Object
        },
    },
    data() {
        return {
            isSubmitted: true,
            loading: false,
            isLoading:true,
            showPopup: true,
            clientData: {},
            clientSearch: "",
            arsConnected: true,
            userIds:[],
            feedId:''
        };
    },
    computed: {
        ...mapGetters(["clientsList","monthlyFeedsList"]),
    },
    methods: {
        ...mapActions([
            "addScheduleFeed",
            "fetchClients",
            "fetchMonthlyFeeds",
            "fetchMonthlyFeedHistory"
        ]),
        getClients(userIds, feedId) {
            if(userIds)
            {
                this.userIds = userIds
            }

            if(feedId)
            {
                this.feedId = feedId;
            }

            var requestData = {
                search: this.clientSearch,
                ids:this.userIds ? this.userIds : []
            };
        
            this.fetchClients(requestData)
            .then((response) => {
                this.clientData = this.clientsList.client_data;
                this.arsConnected = this.clientsList.connectedAccount;
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
                this.error = true;
            });
        },
        saveScheduleFeed() {
            this.isSubmitted = false;
            this.loading = true;
            
            let requestData = {
                usersIds:this.userIds,
                feedId:this.feedId
            };

            if(!confirm('Are You sure ? you want to Re-scheduled Monthly Feed for these users ?'))
            {
                this.isSubmitted = true;
                return;
            }
        
            try {
                axios.post(`${process.env.VUE_APP_BASEURL}/re-schedule-monthly-feeds`, requestData, {
                    headers: {
                        'Content-type': 'application/json',
                        'token': `${localStorage.getItem('token')}`
                    }
                }).then(response => {
                    if (response.data.status === true)
                    {
                        this.$toasted.show(response.data.message, {
                            theme: "bubble",
                            type: "success",
                            position: "top-right",
                            duration: 2000,
                            singleton: true,
                        });
                    }
                    else
                    {
                        this.$toasted.show(response.data.error_message, {
                            theme: "bubble",
                            type: "error",
                            position: "top-center",
                            duration: 2000,
                            singleton: true,
                        });
                    }
                }).catch(error => {
                    this.$toasted.show(response.data.error_message, {
                        theme: "bubble",
                        type: "error",
                        position: "top-center",
                        duration: 2000,
                        singleton: true,
                    });
                });
            }
            finally
            {
                this.isSubmitted = true; // Reset submission state
                this.loading = false;
          
                this.fetchMonthlyFeeds(this.filterData);
                this.scheduleFeedItem.forEach(sc => { 
                    this.$emit('allDisableItem', sc.id); 
                });
          
                this.fetchMonthlyFeedHistory();
                $("#reMonthlyFeedScheduleModal").modal("hide");
                this.$emit('refreshFeed');
            }
        },
    },
    mounted() {
        // this.getClients();
    },
};
</script>

<style scoped>
   w-100 {
   width: 100% !important;
   }
   .img-fluid {
   max-width: 100%;
   height: auto;
   }
   .clients-list-grp ul {
   flex-direction: row;
   gap: 20px;
   flex-wrap: wrap;
   }
   .clients-list-grp ul li {
   border: 1px solid #dfdfdf;
   border-top-width: 1px !important;
   width:250px
   }
</style>