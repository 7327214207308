import {getRequest, postRequest} from '@/helper'

let state = {
    business: {}
}

let getters = {
    business: (state) => {
        return state.business
    }
}

let actions = {
    fetchUserBusiness: async ({ commit }) => {
        let resp = await getRequest(`users/business/view`)
        return resp;
    },
    updateUserBusiness: async({commit}, data) => {
        let {id, name, country, city, postal_code, reviews_from} = data
        let body = {name, country, city, postal_code, reviews_from}
        let resp = await postRequest(`users/business/${id}/update`, body)
        return resp;
    },
    updateUserBusinessPostStatus: async({commit}, data) => {
        let {id, auto_post_status} = data
        let resp = await postRequest(`users/business/update-post-status/${id}`, {auto_post_status})
        return resp;
    },
    addUserBusiness: async({commit}, data) => {
        let { name, country, city, postal_code, reviews_from } = data
        let body = { name, country, city, postal_code, reviews_from }
        let resp = await postRequest(`users/business/add`, body)
        return resp;
    },
    deleteUserBusiness: async ({ commit }, id) => {
        let resp = await getRequest(`users/business/${id}/delete`)
        return resp;
    },
    fetchBusiness: async ({ commit }, id) => {
        let resp = await getRequest(`business/view/${id}`)
        if(resp && resp.status)
        {
            commit('setBusiness', resp.data)
        }
        else
        {
            console.log(resp.message)
            console.error('Business not found')
        }
    },
    updateBusiness: async({commit}, data) => {
        let {id, name, country, city, postal_code, reviews_from, auto_post_status} = data
        let body = {name, country, city, postal_code, reviews_from, auto_post_status}
        let resp = await postRequest(`business/${id}/edit`, body)
        return resp;
    },
    addBusiness: async({commit}, data) => {
        let {user_id, name, country, city, postal_code, reviews_from, auto_post_status} = data
        let body = {user_id, name, country, city, postal_code, reviews_from, auto_post_status}
        let resp = await postRequest(`business/add`, body)
        return resp;
    },
    deleteBusiness: async ({ commit }, id) => {
        let resp = await getRequest(`business/delete/${id}`)
        return resp;
    },
    updateUsersFirstData: async({commit}, data) => {
        let resp = await postRequest(`users/business/set-user-data`, data)
        return resp;
    },
    getUsersFirstData: async({commit}, query) => {
        let resp = await getRequest(`users/business/get-user-data?${query}`)
        return resp;
    },
    fetchUserAutoPostStatus: async ({ commit }) => {
        let resp = await getRequest(`users/business/auto-post-status`)
        return resp;
    },
    updateUserAutoPost: async ({ commit }, data) => {
        let resp = await postRequest(`users/business/auto-post-status`,data)
        return resp;
    },
}

let mutations = {
    setBusiness: (state, business) => {
        return state.business = business
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
