import { getRequest } from '@/helper'

let getStartedModule = {
    state: {
        is_first_loggedin: 1,
        is_metting_btn_clicked:1,
        is_first_account_linked:1,
        is_first_business_added:1,
        is_rvw_tmpl_configured:1,
        is_embd_code_configured:1,
    },
    mutations: {
        set_is_first_loggedin(state, val) {
            state.is_first_loggedin = val;
        },
        set_is_embd_code_configured(state, val) {
            state.is_embd_code_configured = val;
        },
        set_is_rvw_tmpl_configured(state, val) {
            state.is_rvw_tmpl_configured = val;
        },
        set_is_first_business_added(state, val) {
            state.is_first_business_added = val;
        },
        set_is_first_account_linked(state, val) {
            state.is_first_account_linked = val;
        },
        set_is_metting_btn_clicked(state, val) {
            state.is_metting_btn_clicked = val;
        },
    },
    actions: {
        updateGetStartedStatus({ commit }, data) {
            let {key , value} = data;
            commit(`set_${key}`, value);
        },
        refreshGetStartedStatus:function({commit}, type){
            if(type == 'User')
            {
                getRequest("users/business/get-user-data?fields=is_metting_btn_clicked,is_first_account_linked,is_first_loggedin,is_first_business_added,is_rvw_tmpl_configured,is_embd_code_configured").then((resp) => {
                    if(resp.status)
                    {
                        commit(`set_is_first_account_linked`, resp.data.is_first_account_linked)
                        commit(`set_is_metting_btn_clicked`, resp.data.is_metting_btn_clicked)
                        commit(`set_is_first_business_added`, resp.data.is_first_business_added)
                        commit(`set_is_rvw_tmpl_configured`, resp.data.is_rvw_tmpl_configured)
                        commit(`set_is_embd_code_configured`, resp.data.is_embd_code_configured)
                        commit(`set_is_first_loggedin`, resp.data.is_first_loggedin)
                    }
                })
            }
            else
            {
                commit(`set_is_first_account_linked`, 1)
                commit(`set_is_metting_btn_clicked`, 1)
                commit(`set_is_first_business_added`, 1)
                commit(`set_is_rvw_tmpl_configured`, 1)
                commit(`set_is_embd_code_configured`, 1)
                commit(`set_is_first_loggedin`, 1)
            }
        }
    },
    getters: {
        isFirstLoggedin: (state) => state.is_first_loggedin,
        isMeetingBtnClicked:(state) => state.is_metting_btn_clicked,
        isFirstSocialAccountLinked:(state) => state.is_first_account_linked,
        isBussinessIntegerated:(state) => state.is_first_business_added,
        isFirstEmbedCodeCreated:(state) => state.is_embd_code_configured,
        isReviewTemplatesConfigured:(state) => state.is_rvw_tmpl_configured,
    }
}

export default getStartedModule